import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import React, { useEffect, useMemo, useState } from "react";
import Button from "../../components/Button";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast } from "react-toastify";
import { GetpieChart } from "../../apis/pieChart";
import { GetPieChartDetails } from "../../apis/pieChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Notification from "../../components/Notification";
import dayjs from "dayjs";
import TransationSummaryList from "./TransationSummaryList";
import ClaimSubmitDetails from "../claimSubmit/ClaimSubmit";
import ClaimStatusDetails from "../claimSubmit/ClaimStatus";
import PatientDetails from "../patient/PatientDetails";
import Modal from "@mui/material/Modal";
import CustomModal from "./CustomModal";
import getRole from "../../components/utils/getRole";
import CustomRender from "./CustomRender";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { logTelemetry } from "../../logTelemetry";
import { useSelector } from "react-redux";

const modelStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: "max-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  pb: 1,
};

const textFieldStyles = {
  width: "100%",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const tabsObj = [
  {
    label: "Transaction Summary",
    role: null, // No role required
  },
  {
    label: "List",
    role: null, // No role required
  },
  {
    label: "Claim Details",
    role: null, // Role required: 'tscs'
  },
  {
    label: "Claim Status",
    role: null, // Role required: 'mClaimStatus'
  },
];

const TransationSummary = () => {
  const [open, setOpen] = React.useState(false);
  const [currentPieData, setCurrentPieData] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isonHold, setisonHold] = useState(0);
  const [isOnHoldChecked, setisOnHoldChecked] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClosee = () => {
    setShowAlert(false);
    handleClose();
  };
  const [edi837ListObject, setEdi837ListObject] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [dataToTransfer, setDataToTransfer] = useState(null);
  const [valueNeedToSendPie, setValueNeedToSendPie] = useState("");
  const [isFromTS, setIsFromTS] = useState(false);
  const [tabs, settabs] = useState(tabsObj);

  // const updateLocationItem = (e, newVal) => {
  //   setFilterInput((prev) => ({ ...prev, location: newVal }));
  //   setUpdateLocationName(newVal?.companyName);
  //   setUpdateLocationValue(newVal?.companyId);
  // };

  // Function to handle changes in location
  const updateLocationItem = (index, newValue) => {
    // console.log({ index, newValue }, " filter ");
    setFilterInputs((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], location: newValue };
      // console.log({ newState }, " filter ");
      return newState;
    });
    setUpdateLocationName(newValue?.companyName);
    setUpdateLocationValue(newValue?.companyId);
  };

  const [tempIndex, setTempIndex] = useState(0);

  // const breadcrumbItems = [
  //   { label: "Patient List", link: "/patient-list" },
  //   { label: "Transaction Summary", link: "/patient-list/transation-summary" },
  // ];
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));
  // console.log(details, "details");

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  // let locationData = details?.aILocations;
  // locationData.unshift({
  //   companyName: "All",
  //   companyId: "0",
  // });

  const locationData = useMemo(() => {
    const originalLocationData = details?.aILocations || [];
    return [
      {
        companyName: "All",
        companyId: "0",
      },
      ...originalLocationData,
    ];
  }, [details]);

  let companyId =
    details?.companyId != undefined ? details?.companyId : details.CompanyId;
  companyId = details.aILocations.filter((i) => i.companyId == companyId);
  const [UpdateLocationValue, setUpdateLocationValue] = useState("0");
  const [UpdateLocationName, setUpdateLocationName] = useState("All");
  const initialState = {
    location: {
      companyName: "All",
      companyId: "0",
    },
    toDate: dayjs(),
    fromDate: dayjs(),
  };
  // const [fromDate, setfromDate] = useState(null);

  // const [UpdateTransName, setUpdateTransName] = useState("");
  // const [toDate, settoDate] = useState(null);
  // const [dateRange, setdateRange] = useState("1 Day");
  const [transctionTypeList, settransctionTypeList] = useState([]);
  const [DBtransctionTypeList, setDBtransctionTypeList] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [dataFail, setDataFail] = useState({});
  const [dataSs, setDataSs] = useState({});
  const [dataIp, setDataIp] = useState({});
  const [currentIndex, setCurrentIndex] = useState(1);
  const [pds, setpds] = useState([]);
  const [pdi, setpdi] = useState([]);
  const [isFromList, setIsFromList] = useState(false);
  const [isFromList2, setIsFromList2] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [pieData, setPieData] = useState({});

  const pieParams = { height: 280, margin: { right: 300 } };
  const { data, mutateAsync, isLoading, isError } = GetpieChart();
  const {
    data: dataServiceType,
    mutateAsync: mutateServiceTypeAsync,
    isLoading: proceedLoading,
  } = GetPieChartDetails();

  const [transctionDetailsObj, settransctionDetailsObj] = useState({});

  const handledateRange = (index, newVal) => {
    let fromDate = null;
    let toDate = null;

    // Calculate fromDate and toDate based on the selected dateRange
    switch (newVal) {
      case "1 Day":
        // Set fromDate to one day before the current date
        fromDate = dayjs().subtract(1, "day").format("MM/DD/YYYY");
        toDate = dayjs().format("MM/DD/YYYY"); // Set toDate to the current date
        break;
      case "1 Week":
        // Set fromDate to one week before the current date
        fromDate = dayjs().subtract(1, "week").format("MM/DD/YYYY");
        toDate = dayjs().format("MM/DD/YYYY"); // Set toDate to the current date
        break;
      case "1 Month":
        // Set fromDate to one month before the current date
        fromDate = dayjs().subtract(1, "month").format("MM/DD/YYYY");
        toDate = dayjs().format("MM/DD/YYYY"); // Set toDate to the current date
        break;
      case "3 Months":
        // Set fromDate to three months before the current date
        fromDate = dayjs().subtract(3, "month").format("MM/DD/YYYY");
        toDate = dayjs().format("MM/DD/YYYY"); // Set toDate to the current date
        break;
      case "6 Months":
        // Set fromDate to six months before the current date
        fromDate = dayjs().subtract(6, "month").format("MM/DD/YYYY");
        toDate = dayjs().format("MM/DD/YYYY"); // Set toDate to the current date
        break;
      case "1 year":
        // Set fromDate to one year before the current date
        fromDate = dayjs().subtract(1, "year").format("MM/DD/YYYY");
        toDate = dayjs().format("MM/DD/YYYY"); // Set toDate to the current date
        break;
      default:
        // Default to no change in fromDate and toDate
        break;
    }

    // Update the state with the new dateRange, fromDate, and toDate
    // setFilterInput((prevState) => ({
    //   ...prevState,
    //   dateRange: newVal,
    //   fromDate: fromDate,
    //   toDate: toDate,
    // }));
    setFilterInputs((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        dateRange: newVal,
        fromDate: fromDate,
        toDate: toDate,
      };
      return newState;
    });
  };

  const [count, setCount] = useState(1);
  const [isAnythingChanged, setIsAnythingChanged] = useState(false);
  const [rows, setRows] = useState([]);
  // const [overAllData, setOverAllData] = useState({});
  const [loading, setLoading] = useState(false);
  const [count1, setCount1] = useState(1);
  const [count2, setCount2] = useState(1);
  const [isDataObject, setisDataObject] = useState("");
  const [expanded, setExpanded] = useState([1]);
  const [filterInputs, setFilterInputs] = useState([]);
  const [accordionState, setAccordionState] = useState({});

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValue1, setSelectedValue1] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState(null);

  function setDataForRows(rows) {
    setRows(rows);
  }

  function setDataForDataObject(val) {
    setisDataObject(val);
  }

  async function dataa(val) {
    try {
      let dataa = await mutateAsync({
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        providerControlNumber: "554502098",
        orbitAssignedClaimID: "",
        fromDate: "1/1/2024",
        toDate: "4/1/2024",
        RequestType: "",
        requestSubType: "",
        Flag: 1,
      });
      fetchDataAndHandleResponse(dataa);
    } catch (e) {
      logTelemetry("Failed", "TransationSummary", "dataa", e.message, e);
    }
  }

  async function getPieChartDetails(indx, fromWhere, onHoldFlag) {
    const { fromDate, toDate, location } = filterInputs[indx];
    if (!fromDate || (!toDate && onHoldFlag != 1)) {
      setNotify({
        isOpen: true,
        message: "Please select the Dates to continue",
        type: "warning",
      });
      if (!onHoldFlag) {
        setFilterInputs((prevState) => {
          const newState = [...prevState];
          newState[indx] = {
            ...newState[indx],
            fromDate: "null",
            toDate: "null",
            dateRange: "Custom Range",
          };
          return newState;
        });
      }
      return;
    }
    // const { fromDate, toDate, location } = filterInputs[indx];
    setisonHold(0);
    if (onHoldFlag == 1) {
      setisonHold(1);
      setFilterInputs((prevState) => {
        const newState = [...prevState];
        newState[indx] = {
          ...newState[indx],
          fromDate: null,
          toDate: null,
          dateRange: "Custom Range",
        };
        return newState;
      });
    }
    try {
      setLoading(true);
      setCurrentIndex(indx);
      const dataServiceType = await mutateServiceTypeAsync({
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        fromDate,
        toDate,
        RequestType: fromWhere?.requestCode,
        requestSubType: "",
        CompanyLocationID: location?.companyId,
        Flag: 0,
        holdFlag: onHoldFlag == undefined ? 0 : onHoldFlag,
      });
      if (dataServiceType.status && dataServiceType.status.code !== 200) {
        setSubCategory(undefined);
        setSelectedValue(undefined);
        setSelectedValue1(undefined);
        setSelectedValue2(undefined);
        setAccordionState({
          ...accordionState,
          [indx]: fromWhere?.requestCode,
        });

        settransctionDetailsObj([]);
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${dataServiceType.status.message}`,
          type: "error",
        });
        logTelemetry(
          "failed",
          "TransationSummary",
          "getPieChartDetails",
          `${dataServiceType.status.message}`
        );
      } else if (
        dataServiceType.status &&
        dataServiceType.status.code === 200
      ) {
        if (
          dataServiceType.data &&
          dataServiceType.data.requestTypes != null &&
          dataServiceType.data.requestTypes.length > 0
        ) {
          const wholeData = dataServiceType;
          const val = wholeData.data.requestTypes[0].subRequests;

          const isExpanded = expanded.includes(indx);
          if (isExpanded) {
            setAccordionState({
              ...accordionState,
              [indx]: wholeData.data.requestTypes[0],
            });
          } else {
            setAccordionState({
              ...accordionState,
              [indx]: wholeData.data.requestTypes[0],
            });
          }

          setSubCategory(val);
          setSelectedValue(selectedValue);
          setSelectedValue1(selectedValue1);
          setSelectedValue2(selectedValue2);

          settransctionDetailsObj(wholeData.data.requestTypes[0]);
          setLoading(false);
          //setSubCategory
        } else {
          setSubCategory(val);
          setSelectedValue(selectedValue);
          setSelectedValue1(selectedValue1);
          setSelectedValue2(selectedValue2);

          settransctionDetailsObj([]);
          setNotify({
            isOpen: true,
            message: `${"There is no data"}`,
            type: "error",
          });
        }
      }

      if (dataServiceType.response && dataServiceType.response.status !== 200) {
        setNotify({
          isOpen: true,
          message: `${dataServiceType.message}`,
          type: "error",
        });
        logTelemetry(
          "failed",
          "TransationSummary",
          "getPieChartDetails",
          `${dataServiceType.message}`
        );
      }
    } catch (error) {
      logTelemetry(
        "Failed",
        "TransationSummary",
        "getPieChartDetails",
        error.message,
        error
      );
      console.error("Error fetching data:", error);
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "An error occurred while fetching data.",
        type: "error",
      });
    }
  }

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [open]);

  const [dataForCS, setDataForCS] = useState();
  const [dataForCStatus, setdataForCStatus] = useState();

  const handleCheckChange = (event) => {
    setisOnHoldChecked(event.target.checked);
  };

  async function fetchDataAndHandleResponse(data) {
    try {
      if (data) {
        if (data.status && data.status.code !== 200) {
          setLoading(false);
          setNotify({
            isOpen: true,
            message: `${data.status.message}`,
            type: "error",
          });
          logTelemetry(
            "failed",
            "TransationSummary",
            "fetchDataAndHandleResponse",
            `${data.status.message}`
          );
        } else if (data.status && data.status.code === 200) {
          const arr = data.data.requestTypes;
          let filteredCat = data.data.requestTypes;
          // ?.filter((item) =>
          //   getRole(`ts${item.requestCode.toLowerCase()}`)
          // );
          setDBtransctionTypeList(filteredCat);
          setFilterInputs(filteredCat.map((i) => initialState));
          setSubCategory(data.data.requestTypes?.[1]?.subRequests);
          setSelectedValue(
            Object.keys(data.data.requestTypes?.[1]?.subRequests || {})[0]
          );
          settransctionTypeList(arr[1]);
        }
      }
      if (data && data.response && data.response.status !== 200) {
        setNotify({
          isOpen: true,
          message: `${data.response.message}`,
          type: "error",
        });
        logTelemetry(
          "failed",
          "TransationSummary",
          "fetchDataAndHandleResponse",
          `${data.response.message}`
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNotify({
        isOpen: true,
        message: "An error occurred while fetching data.",
        type: "error",
      });
    }
  }

  function setValueFun(val) {
    setValue(val);
    if (val == 2) {
      setIsFromList(true);
    } else if (val == 3) {
      setIsFromList2(true);
    }
  }

  function convertToPhrase(inputString) {
    const result = inputString
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
    return result;
  }

  useEffect(() => {
    dataa();
  }, []);

  const range = [
    "Custom Range",
    "1 Day",
    "1 Week",
    "1 Month",
    "3 Months",
    "6 Months",
    "1 year",
  ];
  // const [expanded, setExpanded] = useState("panel1");

  function bindFinalData(param) {
    setDataToTransfer((prev) => ({
      ...prev,
      ...param,
      payerName: param?.name,
      holdFlag: isonHold,
    }));
    setIsFromTS(true);
    handleClose();
    setValue(1);
  }

  const handleChangeAccc = (index, listData) => () => {
    const isExpanded = expanded.includes(index);
    const newExpanded = isExpanded
      ? expanded.filter((item) => item !== index)
      : [...expanded, index];
    setExpanded(newExpanded);
  };

  const handleRadioChange = (e) => {
    const res = e.target.value;
    setSelectedValue(res);
    setpdi(
      dataIp[res == "byCount" || res == "byLatency" ? "byPayer" : res][
        res == "byCount" || res == "byLatency" ? res : "byCount"
      ]
    );
    setCount(1);
  };

  const handleRadioChange1 = (e) => {
    const res = e.target.value;
    setSelectedValue1(res);
    setpds(
      dataSs[res == "byCount" || res == "byLatency" ? "byPayer" : res][
        res == "byCount" || res == "byLatency" ? res : "byCount"
      ]
    );
    setCount1(1);
  };
  const handleRadioChange2 = (e, indx, wholeArr) => {
    const res = e.target.value;
    setCount2(1);
    setpdf(dataFail[res]["byCount"]);
    setSelectedValue2(res);
  };

  function checkArraysEmpty(obj) {
    // console.log(obj, " obj ");
    return !(
      obj?.byReason?.byCount?.length === 0 &&
      obj?.byReason?.byLatency?.length === 0
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (tabs[value].label === "Claim Details" && isAnythingChanged) {
      setShowAlert(true);
      setTempIndex(newValue);
      handleOpen();
    } else {
      if (tabs[newValue].label === "Claim Details" && isAnythingChanged) {
        setIsAnythingChanged(false);
      }
      setValue(newValue);
      setIsFromTS(false);
      setIsFromList(false);
      setIsFromList2(false);
    }
  };

  const handleContinue = () => {
    handleClosee();
    setValue(tempIndex);
    setIsFromTS(false);
    setIsFromList(false);
    setIsFromList2(false);
  };

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const onItemClickPie = (d, item, subCategory, parentIndex, a, b, c, e) => {
    let filterInput = { ...filterInputs[parentIndex] };
    const params = {
      ...filterInput,
      from: item.label.split("(")[0],
      pRequest_Type: b.requestCode,
      subType: subCategory[d.dataIndex].subRequestType,
      loc: { UpdateLocationName, UpdateLocationValue, filterInput },
      holdFlag: isonHold,
    };
    setDataToTransfer(params);
    setValue(1);
    setIsFromTS(true);
  };

  const handleItemClick = (
    item,
    subCategoryIndex,
    parentIndex,
    from,
    b,
    pRequest_Subtype,
    a,
    c
  ) => {
    let filterInput = { ...filterInputs?.[parentIndex] };
    const params = {
      ...item,
      ...filterInput,
      payerName: item?.name,
      pRequest_Type: b.requestCode,
      pRequest_Subtype:
        pRequest_Subtype == "byPayerCount"
          ? "byCount"
          : pRequest_Subtype == "byPayerLatency"
          ? "bylatency"
          : pRequest_Subtype,
      from: from.subRequestType,
      loc: { UpdateLocationName, UpdateLocationValue, filterInput },
      holdFlag: isonHold,
    };
    setValue(1);
    setDataToTransfer(params);
    setIsFromTS(true);
  };

  const callTabRole = (index) => {
    if (index == "CS") {
      return getRole("tscs") == 0 ? "" : 1;
    }
    if (index == "CSR") {
      return getRole("tscsr") == 0 ? "" : 1;
    }
    if (index == "RTE") {
      return getRole("tsrte") == 0 ? "" : 1;
    }
    if (index == "ERA") {
      return getRole("tsera") == 0 ? "" : 1;
    }
  };

  const viewAllClick = (parentIndex, b, c, pieData, parentData) => {
    let filterInput = { ...filterInputs[parentIndex] };
    const params = {
      ...filterInput,
      // payerName: item.label.split("(")[0],
      pRequest_Type: parentData.requestCode,
      from: b.subRequestType,
      pRequest_Subtype: c,
      loc: {
        UpdateLocationName,
        UpdateLocationValue,
        filterInput,
      },
      parentIndex,
      b,
      c,
      pieData,
    }; // Example parameters
    setDataToTransfer(params);
    setValueNeedToSendPie(c);
    handleOpen();
    setCurrentPieData(pieData);
  };

  function handleSetPieData(value, idx) {
    setPieData((prev) => ({
      ...prev,
      [idx]: value,
    }));
  }

  function handleSetSelectedOption(value, idx) {
    setSelectedOption((prev) => ({
      ...prev,
      [idx]: value,
    }));
  }

  return (
    <>
      <div>
        <Container maxWidth="xl">
          <Box className="mb-2 mt-3">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {tabs.map(
                  (tab, index) => (
                    // Render each tab dynamically using map function
                    // Only render tab if the user has the necessary role
                    // (!tab.role || getRole(tab.role)) && (
                    <Tab
                      key={index}
                      label={tab.label}
                      // Spread any additional props if needed
                      {...a11yProps(index)}
                      sx={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    />
                  )
                  // )
                )}
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <React.Fragment>
                {DBtransctionTypeList?.length > 0 &&
                  DBtransctionTypeList?.map((list, index) => (
                    <React.Fragment key={index}>
                      {callTabRole(list.requestCode) && (
                        <Accordion
                          expanded={expanded.includes(index)}
                          onChange={handleChangeAccc(index, list)}
                          // onChange={handleChangeAcc(`panel${index}`, list)}
                          // sx={{ backgroundColor: "#e0e0e0", color: "#000" }}
                        >
                          <div className="acc">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${index}bh-content`}
                              id={`panel${index}bh-header`}
                              sx={{
                                backgroundColor: "#efefef",
                              }}
                              className="font-medium"
                            >
                              {list.requestType}
                            </AccordionSummary>
                          </div>

                          <AccordionDetails>
                            <Grid
                              gap={2}
                              columns={12}
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item xs={3.4} sm={3.4}>
                                <Autocomplete
                                  autoFocus
                                  id={"combo-box-demo" + index}
                                  options={locationData}
                                  getOptionLabel={(option) =>
                                    option?.companyName
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.companyName === value.companyName
                                  }
                                  onChange={(event, newValue) =>
                                    updateLocationItem(index, newValue)
                                  }
                                  value={
                                    filterInputs?.[index]?.location || null
                                  }
                                  size="small"
                                  // value={filterInput?.location}
                                  style={textFieldStyles}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Location" />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={2} sm={2}>
                                <Autocomplete
                                  disablePortal
                                  id={"combo-box-demo1" + index}
                                  options={range}
                                  size="small"
                                  // value={filterInput?.dateRange}
                                  // sx={{ width: 160 }}
                                  onChange={(event, newValue) =>
                                    handledateRange(index, newValue)
                                  }
                                  value={filterInputs[index]?.dateRange}
                                  style={textFieldStyles}
                                  disableClearable
                                  // onChange={(e, newVal) => handledateRange(e, newVal)}

                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Range"
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs className="displayGrid">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      // onChange={(newValue) => {
                                      //   // setfromDate(newValue);
                                      //   setFilterInput((prvState)=>{
                                      //   return ({
                                      //     ...prvState,
                                      //     fromDate:newValue,
                                      //   }));
                                      // }
                                      // }}
                                      onChange={(val) => {
                                        // setFilterInput((prvState) => {
                                        //   return {
                                        //     ...prvState,
                                        //     fromDate: val,
                                        //    ,
                                        //   };
                                        // });
                                        setFilterInputs((prevState) => {
                                          const newState = [...prevState];
                                          newState[index] = {
                                            ...newState[index],
                                            fromDate: val,
                                            dateRange: "Custom Range",
                                          };
                                          return newState;
                                        });
                                      }}
                                      value={
                                        filterInputs[index]?.fromDate
                                          ? dayjs(filterInputs[index]?.fromDate)
                                          : null
                                      }
                                      maxDate={dayjs()}
                                      inputFormat="MM/dd/yyyy"
                                      style={textFieldStyles}
                                      size="small"
                                      label="From Date"
                                      slotProps={{
                                        textField: { size: "small" },
                                      }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs className="displayGrid">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DatePicker"]}
                                    // style={{ marginLeft: "10px" }}
                                  >
                                    <DatePicker
                                      maxDate={dayjs()}
                                      onChange={(val) => {
                                        const { fromDate } =
                                          filterInputs[index] || {};
                                        if (
                                          fromDate &&
                                          dayjs(fromDate).isAfter(val)
                                        ) {
                                          toast.error(
                                            "From date cannot be greater than to date!",
                                            {
                                              position: "top-right",
                                              autoClose: 3000,
                                              hideProgressBar: false,
                                              closeOnClick: true,
                                              pauseOnHover: true,
                                              draggable: true,
                                              theme: "light",
                                              // style: {
                                              //   width: "500px",
                                              // },
                                            }
                                          );
                                          setFilterInputs((prevState) => {
                                            const newState = [...prevState];
                                            newState[index] = {
                                              ...newState[index],
                                              toDate: prevState[index]?.toDate,
                                              dateRange: "Custom Range",
                                            };
                                            return newState;
                                          });
                                        } else {
                                          setFilterInputs((prevState) => {
                                            const newState = [...prevState];
                                            newState[index] = {
                                              ...newState[index],
                                              toDate: val,
                                              dateRange: "Custom Range",
                                            };
                                            return newState;
                                          });
                                        }
                                      }}
                                      inputFormat="MM/dd/yyyy"
                                      style={textFieldStyles}
                                      value={
                                        filterInputs[index]?.toDate
                                          ? dayjs(filterInputs[index]?.toDate)
                                          : null
                                      }
                                      size="small"
                                      label="To Date"
                                      slotProps={{
                                        textField: { size: "small" },
                                      }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={1} sm={1}>
                                <Button
                                  classname={
                                    isOnHoldChecked == true
                                      ? list.requestCode == "CS"
                                        ? "button-styles btnHeight disabled"
                                        : "button-styles btnHeight"
                                      : "button-styles btnHeight"
                                  }
                                  text="Submit"
                                  filled={true}
                                  onclick={() =>
                                    getPieChartDetails(index, list)
                                  }
                                />
                              </Grid>
                              {list.requestCode == "CS" && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className="flex justify-center"
                                  >
                                    <span className="font-semibold">(OR)</span>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className="flex justify-center !mb-2"
                                  >
                                    <Button
                                      text="Display All Claims On Hold"
                                      classname={"button-styles btnHeight mb-1"}
                                      filled
                                      onclick={() =>
                                        getPieChartDetails(index, list, 1)
                                      }
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>

                            {Object.keys(accordionState).length > 0 &&
                              accordionState?.[index]?.subRequests?.length >
                                0 && (
                                <Grid container>
                                  <Grid
                                    item
                                    sm={12}
                                    className="my-2"
                                    display={{ xs: "none", sm: "block" }}
                                  >
                                    <Divider variant="middle" />
                                  </Grid>
                                  <CustomRender
                                    subCategory={
                                      accordionState?.[index]?.subRequests
                                    }
                                    // getColor={getColor}
                                    pieParams={pieParams}
                                    pIndex={index}
                                    pList={list}
                                    onItemClickPie={onItemClickPie}
                                    // pIndex={index}
                                    onPieChartClick={handleItemClick}
                                    viewAllClick={viewAllClick}
                                    e={accordionState}
                                    selectedOption={selectedOption}
                                    setSelectedOption={handleSetSelectedOption}
                                    setPieData={handleSetPieData}
                                    pieData={pieData}
                                  />

                                  <CustomRender
                                    data={accordionState?.[index]?.subRequests}
                                    parentData={{
                                      ...accordionState?.[index],
                                      pindex: index,
                                    }}
                                    pIndex={index}
                                    pList={list}
                                    onPieChartClick={handleItemClick}
                                    viewAllClick={viewAllClick}
                                    e={accordionState}
                                    selectedOption={selectedOption}
                                    setSelectedOption={handleSetSelectedOption}
                                    setPieData={handleSetPieData}
                                    pieData={pieData}
                                  />
                                </Grid>
                              )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </React.Fragment>
                  ))}

                <Notification notify={notify} setNotify={setNotify} />
              </React.Fragment>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <TransationSummaryList
                dataFromTS={dataToTransfer}
                isFrom={isFromTS}
                setDataForRows={setDataForRows}
                rows={rows}
                setValue={setValueFun}
                setDataForCS={setDataForCS}
                setdataForCStatus={setdataForCStatus}
                settabs={settabs}
              />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <ClaimSubmitDetails
                dataFromTSL={dataForCS}
                setDataFromTSL={setDataForCS}
                isFromList={isFromList}
                edi837ListObject={edi837ListObject}
                setEdi837ListObject={setEdi837ListObject}
                isAnythingChanged={isAnythingChanged}
                setIsAnythingChanged={setIsAnythingChanged}
                setdataForCStatus={setdataForCStatus}
                setValue={setValueFun}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <ClaimStatusDetails
                dataFromTSL={dataForCStatus}
                setDataFromTSL={setdataForCStatus}
                isDataObject={isDataObject}
                isFromList={isFromList2}
                setDataForDataObject={setDataForDataObject}
              />

              {/* <Form /> */}
            </CustomTabPanel>

            {/* <CustomTabPanel value={value} index={3}>
              <ClaimStatusDetails
                dataFromTSL={dataForCStatus}
                isDataObject={isDataObject}
                setDataFromTSL={setdataForCStatus}
                isFromList={isFromList2}
                setDataForDataObject={setDataForDataObject}
              />
            </CustomTabPanel>    */}
            {/* <CustomTabPanel value={value} index={3}>
              <ClaimStatusDetails
                dataFromTSL={dataForCStatus}
                isDataObject={isDataObject}
                setDataFromTSL={setdataForCStatus}
                isFromList={isFromList2}
                setDataForDataObject={setDataForDataObject}
              />
            </CustomTabPanel> */}

            <CustomTabPanel value={value} index={4}>
              <PatientDetails
                dataFromTSL={dataForCStatus}
                isDataObject={isDataObject}
                setDataFromTSL={setdataForCStatus}
                isFromList={isFromList2}
                setDataForDataObject={setDataForDataObject}
              />
            </CustomTabPanel>
          </Box>
        </Container>
        <Backdrop
          sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <Box sx={modelStyles}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>

          {showAlert || currentPieData == "" ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box mt={2} textAlign="center">
                <p>
                  Once change the tab, the changes you made until now will be
                  lost. Please confirm.
                </p>
              </Box>
              <Box mt={4} display={"flex"}>
                <Button
                  variant="contained"
                  color="primary"
                  onclick={handleContinue}
                  classname={"button-styles btnHeight"}
                  text="Continue"
                  startIcon={<CheckCircleIcon />}
                  filled
                ></Button>
                &nbsp; &nbsp;
                <Button
                  variant="outlined"
                  color="secondary"
                  classname={"button-styles btnHeight"}
                  onclick={handleClosee}
                  text={"Close"}
                  filled
                  endIcon={<CloseIcon />}
                ></Button>
              </Box>
            </Box>
          ) : (
            <CustomModal
              data={currentPieData}
              close={handleClose}
              checkFun={checkArraysEmpty}
              selectedVal={valueNeedToSendPie}
              setChangeTab={setValue}
              setLabelData={bindFinalData}
              onItemClick={handleItemClick}
            />
          )}
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </Modal>
    </>
  );
};

export default TransationSummary;
