import React, { useEffect, useMemo, useState } from "react";
import Logo from "../images/Logo.png";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QRCode from "qrcode";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
  Button as MuiButton,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Box from "@mui/material/Box";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import MyContext from "./MyContext";
import { useContext } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { UpdateQRGenerator } from "../apis/qrgenerator";
import DownloadIcon from "@mui/icons-material/Download";
import CopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import TemporaryDrawer from "./Sidebar";
import { DocumentContext } from "./utils/DocumentContextProvider";
import { appVersion } from "../versionValue";
import { GetSessionUpdatedDataApi } from "../apis/sessionUpdate";
import { sessionOutPayload } from "./utils/sessionOutHandler";
import { persistor } from "../redux/store";
import { useSelector } from "react-redux";
const Header = (props) => {
  const { isLoading, mutateAsync: mutateAsyncSessionData } =
    GetSessionUpdatedDataApi();

  const [openS, setOpenS] = useState(false);
  const { resetFormData } = useContext(DocumentContext);
  const toggleDrawer = (newOpen) => () => {
    alert(newOpen);
    setOpenS(newOpen);
  };
  const { childwindow } = props;
  // const { details?.companyId } = useContext(MyContext);
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));
  const loginDetails = useSelector((state) => state.loginDetails);

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const [url, setUrl] = useState("");
  const [qr, setQr] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [qrLocationId, setqrLocationId] = useState("");
  const [qrLocationName, setqrLocationName] = useState("");
  const [generateDisable, setgenerateDisable] = useState(false);
  let [anchorEl, setAnchorEl] = useState(null);
  const drawerWidth = 260;
  const textFieldStyles = {
    width: "98%",
  };
  const { mutateAsync, data } = UpdateQRGenerator();

  const [mobileOpen, setMobileOpen] = useState(false);
  // const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    // setIsClosing(false);
    setMobileOpen(false);
  };

  // const handleDrawerTransitionEnd = () => {
  //   setIsClosing(false);
  // };

  const updateSessionData = async (mutateAsyncSessionData) => {
    const requestPayload = sessionOutPayload(details);
    try {
      const response = await mutateAsyncSessionData(requestPayload);
      return response;
    } catch (error) {
      console.error("Error updating session:", error);
      throw error;
    }
  };

  const handleDrawerToggle = () => {
    if (!openS) {
      setOpenS(true);
      // setMobileOpen(!mobileOpen);
    }
  };
  let locationData;
  let companyId = null;
  try {
    locationData = details?.aILocations?.map((i) => i.companyName);
    companyId =
      details?.companyId != undefined ? details?.companyId : details?.CompanyId;
    companyId = details?.aILocations?.filter((i) => i?.companyId == companyId);
  } catch (e) {
    console.log(e, " error locationData");
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(false);

  const GenerateQRCode = () => {
    CallGenearteQrCode(1, qrLocationId);
  };

  async function CallGenearteQrCode(val, locationId) {
    await mutateAsync({
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },

      pageNumber: val,
      locationId: locationId,
    });
  }

  useEffect(() => {
    if (data) {
      setOpen(true);

      if (data?.status && data?.status?.code == 400) {
        seterrorMessage(data?.status?.message);
        setQr("");
        setUrl("");
        setgenerateDisable(true);
      }
      if (data?.status && data?.status?.code == 200) {
        seterrorMessage("");
        setgenerateDisable(false);
        setUrl(details?.qrUrlNavigate + "capture?guid=" + data?.data?.lastName);
        var stringUrl =
          details?.qrUrlNavigate + "capture?guid=" + data?.data?.lastName;
        QRCode.toDataURL(
          stringUrl,
          {
            width: 800,
            margin: 2,
            color: {
              dark: "#335383FF",
              light: "#EEEEEEFF",
            },
          },
          (err, stringUrl) => {
            if (err) return console.error(err);
            setQr(stringUrl);
          }
        );
      }
    }
  }, [data]);

  const handleClickOpen = () => {
    setqrLocationId(details?.companyId);
    setqrLocationName(companyId[0]?.companyName);
    CallGenearteQrCode(0, details?.companyId);
  };

  const callQRLocationChange = (e) => {
    seterrorMessage("");
    let name = e.target.innerHTML;
    let id = details?.aILocations?.filter((i) => i?.companyName == name);
    if (id[0]?.companyId != undefined && id[0]?.companyId > 0) {
      CallGenearteQrCode(0, id[0]?.companyId);
      setqrLocationName(id[0]?.companyName);
      setqrLocationId(id[0]?.companyId);
    } else {
      seterrorMessage("Select the location");
      setqrLocationName("");
      setQr("");
      setUrl("");
      setgenerateDisable(false);
    }
  };
  async function copyToClip() {
    await navigator.clipboard.writeText(url);
  }
  const handleDialogClose = () => {
    setOpen(false);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  let navigate = useNavigate();

  function navigateToRoute(route) {
    resetFormData();
    setOpenS(false);
    setTimeout(() => {
      navigate(route);
    }, 100);
  }

  // const handleLogout = () => {
  //   updateSessionData(mutateAsyncSessionData);
  //   sessionStorage.clear();
  //   // navigate("/login");
  //   window.location.href = "/location";
  // };

  async function handleLogout() {
    handleClose();
    try {
      await updateSessionData(mutateAsyncSessionData);
      sessionStorage.clear();
      persistor.purge();
      window.location.href = "/login";
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  const handleClick = () => {
    var urlstr =
      details?.companyCode + "|" + details?.userName + "|80007|70007|60042|0";
    var encod = window.btoa(urlstr);
    var navstr = details?.coreUrlNavigate + "?" + encod;
    window.open(navstr);
  };
  // Remove this const when copying and pasting into your project.
  const container =
    childwindow !== undefined ? () => window().document.body : undefined;
  return (
    <Grid
      className="fixed"
      sx={{
        zIndex: { sm: 100 },
        top: 0,
      }}
    >
      <Grid className="flex justify-between items-center h-16 bg-white drop-shadow-md px-2 md:px-8 w-100">
        <div className="flex justify-between items-center ">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 5 }}
          >
            <MenuRoundedIcon color="primary" sx={{ fontSize: 30 }} />
          </IconButton>
          <div className="cursor-pointer">
            <img src={Logo} alt="logo" className="h-8" />
          </div>
        </div>

        <div className="flex items-center">
          {details?.aiAccessRights?.qrScanAccess == 1 && (
            <div className="mr-5">
              <Tooltip title="QR Scanner">
                <MuiButton
                  onClick={handleClickOpen}
                  className={"filterButton"}
                  color="primary"
                >
                  <QrCodeScannerIcon />
                </MuiButton>
              </Tooltip>
            </div>
          )}
          <div>
            <Typography
              variant="caption"
              gutterBottom
              className="justify-right Primary"
            >
              Primary Location
            </Typography>
            <Typography
              style={{ fontWeight: "bold", fontSize: "13px" }}
              variant="caption"
              align="center"
              display="block"
              gutterBottom
            >
              {details?.companyName}
              <br />
            </Typography>
          </div>

          <div>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleMenu}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <AccountCircle sx={{ width: 35, height: 35 }}>M</AccountCircle>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={Boolean(anchorEl)}
              onClose={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <span>
                  {details?.lastName} {details?.firstName} (
                  {details?.companyRoleName})
                </span>
              </MenuItem>
              <MenuItem className="!justify-between">
                <span>{details?.companyCode}</span>
                <span
                  style={{
                    paddingLeft: "20px",
                    float: "right",
                    fontWeight: "bold",
                  }}
                >
                  V {appVersion}
                </span>
              </MenuItem>
              <Divider />
              <MenuItem className="!justify-between">
                <Button
                  onClick={handleLogout}
                  variant="outlined"
                  startIcon={<LogoutIcon />}
                  className="button-styles"
                >
                  Logout
                </Button>
                {details?.aiAccessRights?.coreAppAccess == 1 && (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleClick}
                  >
                    Administration
                  </Link>
                )}
              </MenuItem>
            </Menu>
          </div>
        </div>

        {/* QR CODE */}
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="qr_pop"
        >
          <div sx={{ backgroundColor: "#1b2034;" }}>
            <DialogContent>
              <Grid container className="my-2">
                <Grid item xs={9}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={locationData ?? null}
                    size="small"
                    value={qrLocationName}
                    onChange={(event, newValue) => {
                      callQRLocationChange(event);
                    }}
                    // sx={{ width: 160 }}
                    style={textFieldStyles}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Location" />
                    )}
                  />
                </Grid>
                <Grid item xs={1} className="!ml-2">
                  {qr.length > 0 && (
                    <Tooltip placement="top-end" title="Download QR Code">
                      <MuiButton
                        className={"filterButton"}
                        href={qr}
                        download="qrcode.png"
                      >
                        <DownloadIcon />
                      </MuiButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={2}>
                  {generateDisable && (
                    <Button
                      style={{ textTransform: "none" }}
                      variant="contained"
                      onClick={GenerateQRCode}
                    >
                      Generate
                    </Button>
                  )}
                  <div className="absolute top-3 right-3">
                    <CloseIcon
                      onClick={() => {
                        handleDialogClose();
                      }}
                      color="primary"
                      className="cursor-pointer windowClose"
                    />
                  </div>
                </Grid>
              </Grid>
              <Divider />
              <Grid container className="!w-[350px] m-auto mt-[20px]">
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  {errorMessage.length > 0 && (
                    <div className="opuperrorMesg">{errorMessage}</div>
                  )}
                  {qr && (
                    <>
                      <img style={{ objectFit: "contain" }} src={qr} />
                    </>
                  )}
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Grid container className="items-center">
                <Grid item xs={1}>
                  {qr.length > 0 && (
                    <Tooltip placement="top-end" title="Copy Link">
                      <MuiButton
                        className={"filterButton"}
                        onClick={copyToClip}
                      >
                        <CopyIcon />
                      </MuiButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={10} className="!ml-2">
                  <Link target="_blank" href={url}>
                    {url}
                  </Link>
                </Grid>
              </Grid>
            </DialogActions>
          </div>
        </Dialog>
      </Grid>
      <Grid>
        {mobileOpen && (
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
            onClick={handleDrawerClose}
            onKeyDown={handleDrawerClose}
          >
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              // onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  // marginTop: "60px"
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  marginTop: { xs: "40px", sm: "65px" },
                },
              }}
              onClose={handleDrawerClose}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        )}

        <TemporaryDrawer
          open={openS}
          setOpen={setOpenS}
          toggleDrawer={toggleDrawer}
          navigateToRoute={navigateToRoute}
          details={details}
        />
      </Grid>
      {/* //start */}
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* //end */}
    </Grid>
  );
};
Header.propTypes = {
  childwindow: PropTypes.func,
};
export default Header;
