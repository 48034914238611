import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  Grid,
  Button,
  Badge,
  InputBase,
  Divider,
  IconButton,
  Paper,
  Typography,
  Button as MuiButton,
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  GetDocumentListApi,
  GetLookUpsForDVApi,
  GetExportElements,
} from "../../apis/documentVerification";
// import MyContext from "../../components/MyContext";
import Notification from "../../components/Notification";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialogs from "../../components/DocDialogs";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import convertToAgGridFormat from "../../components/utils/convertToAgGridFormat";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import useCommonParams from "../../components/utils/useCommonParams";
import { DocumentContext } from "../../components/utils/DocumentContextProvider";
import { logTelemetry } from "../../logTelemetry";
import * as XLSX from "xlsx";
import AutocompleteModal from "./AutocompleteModal";
import ClearIcon from "@mui/icons-material/Clear";
import ClearStatesConfirmationDialog from "../importModule/ClearStatesConfirmationDialog";
import { setActiveComponent } from "../../redux/actions/index";
import {
  setLookUpInfo,
  setdId,
  setFilteredData,
  setFormData,
  setPageModel,
  setSortModel,
  setFilterModel,
  setFiltersApplied,
  resetData,
} from "../../redux/actions/documentRecognizer";

import getErrorMessage from "../../components/utils/getErrorMessage";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearAllIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from "react-redux";
import {
  filterFormInitialData,
  resetInitData,
} from "../../components/utils/SidebarRoutes";
// import { setFormData } from "../../redux/reducers/documentRecognizerReducer";

const DocumentVerification = () => {
  const dispatch = useDispatch();
  let timerId;
  const commonParams = useCommonParams();
  // const {
  //   // formData = {},
  //   // setFormData,
  //   // filterModel,
  //   // sortModel,
  //   // setSortModel,
  //   // setFilterModel,
  //   // setFiltersApplied,
  //   // filtersApplied,
  //   // pageModel,
  //   // setPageModel,
  // } = useContext(DocumentContext);
  const gridRef = useRef(null);
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // editable: true,
      //   floatingFilter: true,
      // filters: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
    }),
    []
  );
  // const navigate = useNavigate();
  const { mutateAsync, isLoading } = GetDocumentListApi();
  const { mutateAsync: lookUpsApi } = GetLookUpsForDVApi();
  const { mutateAsync: GetExportElementsApi } = GetExportElements();

  const loginDetails = useSelector((state) => state.loginDetails);
  const [isFromMore, setIsFromMore] = useState(false);
  const currentComponentData = useSelector(
    (state) =>
      state?.previousComponent?.currentComponentData ??
      state?.previousComponent?.previousComponentData
  );
  const {
    formData = {}, // Fallback to empty object
    pageModel = {}, // Fallback to empty object
    sortModel = null, // Default value
    filterModel,
    filtersApplied,
  } = currentComponentData || {}; // Fallback to empty object if null

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const [loading, setLoading] = useState(false);
  const [lookUpData, setLookUpData] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const [rows, setRows] = useState([]);
  const [mopen, setmOpen] = useState(false);
  const [type, setType] = useState("");
  const [value, setValue] = useState(null);
  const options = lookUpData?.inputTypeList?.map((option) => ({
    name: option.description,
    value: typeof option.id !== "string" ? `${option.id}` : option.id,
  }));

  const handleOpen = (type) => {
    setmOpen(true);
    setType(type);
    setIsFromMore(false);
  };

  const setValuee = (e, newVal) => setValue(newVal);
  // const [filtersApplied, setFiltersApplied] = useState(false);
  const [subStatusData, setSubStatusData] = useState([]);
  const [open, setOpen] = useState(false);
  let [filters, setFilters] = useState({});
  // let [docIds, setDocIds] = useState("0");
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [columns, setColumns] = useState([
    // { field: "view", headerName: "View", width: 150 },
    { field: "batchCode", headerName: "Batch Code", width: 200 },
    { field: "createDate", headerName: "Create Date", width: 160 },
    { field: "createUser", headerName: "Created By", width: 150 },
    { field: "documentId", headerName: "Document ID", width: 160 },
    { field: "docType", headerName: "Document Type", width: 180 },
    { field: "documentName", headerName: "Document Name", width: 250 },
    { field: "docOCRstatus", headerName: "Process Status", width: 250 },
    { field: "docOCRsubstatus", headerName: "Process Substatus", width: 220 },
    { field: "firstName", headerName: "Patient First Name", width: 200 },
    { field: "lastName", headerName: "Patient Last Name", width: 200 },
    { field: "patientDOB", headerName: "Patient DOB", width: 160 },
    { field: "payerName", headerName: "Payer Name", width: 220 },
    { field: "payerId", headerName: "Payer ID", width: 200 },
    { field: "subscriberId", headerName: "Subscriber ID", width: 170 },
    {
      field: "verificationStatus",
      headerName: "Verification Status",
      width: 200,
    },
    { field: "assignToName", headerName: "Assigned To", width: 160 },
    { field: "updateDate", headerName: "Modified Date", width: 165 },
    { field: "locationName", headerName: "Location", width: 220 },
    { field: "inputTypeDesc", headerName: "File Type", width: 400 },
  ]);

  const intial = {
    open: false,
    title: "",
    subTitle: "",
    type: "",
  };
  const [dialogState, setDialogState] = useState(intial);
  let [anchorEl, setAnchorEl] = useState(null);

  const handleConfirm = (title) => {
    if (title === "Attention Required") {
      handleClose1();
      getDocumentList(filterFormInitialData);
      dispatch(resetData(resetInitData));
    }
  };

  const agree = () => {
    setOpen(false);
    // setFilters({});
  };
  const disagree = () => {
    setOpen(false);
  };

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      // params.api.sizeColumnsToFit();
      if (params?.columnApi) {
        const columnIds = ["inputTypeDesc"];
        params?.columnApi.autoSizeColumns(columnIds);
      }
      params.api.addEventListener("firstDataRendered", () => {
        if (filterModel) {
          params.api.setFilterModel(filterModel);
        }
        if (sortModel) {
          // params.api.setSortModel(sortModel);
          gridRef?.current?.api?.applyColumnState({
            state: sortModel,
            defaultState: { sort: null },
          });
        }
        // params?.api?.setPaginationPageSize(pageModel.pageSize); // Set page size
        params?.api?.paginationGoToPage(pageModel?.page);
      });
    },
    [filterModel, sortModel]
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "for") {
      let fromDate;
      if (formData?.daysOrMonths === "days") {
        fromDate = dayjs(formData?.toDate)?.subtract(
          parseInt(value, 10),
          "day"
        );
      } else if (formData?.daysOrMonths === "weeks") {
        fromDate = dayjs(formData?.toDate)?.subtract(
          parseInt(value, 10),
          "week"
        );
      } else {
        fromDate = dayjs(formData?.toDate)?.subtract(
          parseInt(value, 10),
          "month"
        );
      }
      dispatch(
        setFormData({
          ...formData,
          [name]: value,
          fromDate,
        })
      );
    } else if (name == "Batch Number") {
      dispatch(
        setFormData({
          ...formData,
          batchNumber: value,
        })
      );
    } else {
      dispatch(setFormData({ ...formData, [name]: value }));
      if (name === "daysOrMonths") {
        let fromDate;
        if (value === "days") {
          fromDate = dayjs(formData?.toDate)?.subtract(
            parseInt(formData?.for, 10),
            "day"
          );
        } else if (value === "weeks") {
          fromDate = dayjs(formData?.toDate)?.subtract(
            parseInt(formData?.for, 10),
            "week"
          );
        } else {
          fromDate = dayjs(formData?.toDate)?.subtract(
            parseInt(formData?.for, 10),
            "month"
          );
        }
        dispatch(
          setFormData({
            ...formData,
            [name]: value,
            fromDate,
          })
        );
      }
    }
  };
  const handleDateChange = (value, name) => {
    // const { name, value } = e.target;
    if (name === "fromDate") {
      dispatch(setFormData({ ...formData, [name]: value, for: "" }));
      return;
    }
    dispatch(setFormData({ ...formData, [name]: value }));
  };
  const handleAutocompleteChange = (name, value) => {
    if (name === "status") {
      const res = lookUpData?.subStatusList?.filter(
        (item) => item.code === value.code
      );
      res.unshift({
        description: "All",
        key: "0",
      });
      setSubStatusData(res);
    }
    dispatch(setFormData({ ...formData, [name]: value }));
  };

  // const btnSingleClick = () =>
  //   navigate("/patient-list/document-verification/single-document", {
  //     state: lookUpData,
  //   });

  // const btnSingleClick = () => {
  //   const newComponent = "/patient-list/document-verification/single-document";
  //   console.log("Dispatching new component:", newComponent);
  //   dispatch(setActiveComponent(newComponent));
  //   dispatch(setLookUpInfo(lookUpData));
  //   // state: lookUpData,
  // };
  const btnSingleClick = () => {
    dispatch(setLookUpInfo(lookUpData));
    dispatch(setdId(null));
    const newComponent = "/patient-list/document-verification/single-document";
    dispatch(setActiveComponent(newComponent));
  };

  function downloadExcel(
    data,
    filename = `ExportList_${dayjs().format("DDMMYYYY")}.xlsx`
  ) {
    if (data.length === 0) {
      console.error("No data provided for export.");
      return;
    }

    // Create the worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Append the worksheet to the workbook without specifying a sheet name
    XLSX.utils.book_append_sheet(workbook, worksheet);

    // Generate a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the buffer
    const excelBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    // Create a URL for the Blob
    const excelUrl = URL.createObjectURL(excelBlob);

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL after download
    URL.revokeObjectURL(excelUrl);
  }

  const btnExtractElementsClick = async (docIds) => {
    const payload = {
      commonParams,
      ConfigId: "0",
      ModuleId: "0",
      SectionId: "0",
      RecordId: docIds,
      RequestType: "0",
    };
    setLoading(true);
    try {
      let res = await GetExportElementsApi(payload);
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        downloadExcel(res?.data?.searchValues);
        setLoading(false);
        setValue(null);
      } else {
        logTelemetry(
          "failed",
          "DocumentVerification",
          "btnExtractElementsClick",
          `${res?.status?.message}`
        );
        setLoading(false);
        setNotify({
          isOpen: true,
          message: getErrorMessage(res?.status?.message),
          type: "error",
        });
        setValue(null);
      }
    } catch (e) {
      setValue(null);
      logTelemetry(
        "Failed",
        "DocumentVerification",
        "btnExtractElementsClick",
        e.message,
        e
      );
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "btnExtractElementsClick : " + e.message,
        type: "error",
      });
    }
  };

  // const btnBatchClick = () =>
  //   navigate("/patient-list/document-verification/batch-upload", {
  //     state: lookUpData,
  //   });
  const btnBatchClick = () => {
    const newComponent = "/patient-list/document-verification/batch-upload";
    dispatch(setActiveComponent(newComponent));
    dispatch(setLookUpInfo(lookUpData));
  };

  useEffect(() => {
    getDocumentList(formData);
    console.log();
    lookUpsApiCall();
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  async function getDocumentList(param) {
    setLoading(true);
    try {
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "92437",
        moduleId: "0",
        SectionId: "0",
        fromDate:
          (param.fromDate && dayjs(param.fromDate).format("MM/DD/YYYY")) ??
          "01/01/2023",
        toDate:
          (param.toDate && dayjs(param.toDate).format("MM/DD/YYYY")) ??
          "07/01/2024",
        recordStatus: param?.status?.code ?? "All",
        createdBy: param?.CreatedBy?.value ?? "0",
        locationId: param?.location?.companyId ?? "0",
        dateFilter: param?.statusDate?.value ?? "CDT",
        assignTo: param?.assignedTo?.id ?? "0",
        displayFlag: "0", // always
        subStatus: param?.subStatus?.type ?? "0",
        searchValue: param?.searchVal || "",
        dataSrc: param?.dataSource?.description ?? "",
        dataSubProcess: param?.subProcess?.code ?? "All",
        batchNumber: param?.batchNumber || "",
      };
      const res = await mutateAsync(payload);
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        let data = res?.data;
        const rows = data?.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
        setRows(rows);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "DocumentVerification",
          "getDocumentList",
          `${res?.status?.message}`
        );
        setLoading(false);
        setRows([]);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "error",
        });
        if (res?.status?.message?.includes("session")) {
          timerId = setTimeout(() => {
            window?.sessionStorage?.clear();
            window?.localStorage?.clear();
            window.location.href = "/login";
          }, 1500);
        }
      }
    } catch (e) {
      logTelemetry(
        "Failed",
        "DocumentVerification",
        "getDocumentList",
        e.message,
        e
      );
      console.error(e, "error in catch");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "getDocumentList :" + e.message,
        type: "error",
      });
    }
  }

  const handleClose1 = () => {
    setDialogState(intial);
    dispatch(
      setFormData({
        ...formData,
        searchVal: "",
      })
    );
  };

  function handleClearValues() {
    // dispatch(setFiltersApplied(false));
    dispatch(resetData(resetInitData));
    setOpen(false);
    getDocumentList(filterFormInitialData);
  }

  async function lookUpsApiCall() {
    setLoading(true);
    try {
      // console.clear();
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "92437",
        moduleId: "0",
        SectionId: "0",
        RequestType: "0",
      };
      const res = await lookUpsApi(payload);
      // console.log(res, " Result ");
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        res?.data?.statusList?.length > 0
          ? res?.data?.statusList?.unshift({
              description: "All",
              code: "0",
            })
          : [];
        setLookUpData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "error",
        });
        logTelemetry(
          "failed",
          "DocumentVerification",
          "lookUpsApiCall",
          `${res?.status?.message}`
        );
      }
    } catch (e) {
      logTelemetry(
        "Failed",
        "DocumentVerification",
        "lookUpsApiCall",
        e.message,
        e
      );
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "lookUpsApicall : " + e.message,
        type: "error",
      });
    }
  }

  const btnFilterClick = () => {
    setOpen(true);
  };

  function handleSubmit() {
    // console.log(formData);
    getDocumentList(formData);
    dispatch(setFiltersApplied(true));
    setOpen(false);
  }

  function btnRefreshClick() {
    if (filtersApplied) {
      setDialogState({
        open: true,
        title: "Attention Required",
        subTitle: "Are you sure you want to clear all the filters ? ",
        type: "double",
      });
    } else {
      handleConfirm("Attention Required");
    }
  }

  function btnViewClick(dId, res) {
    dispatch(
      setActiveComponent("/patient-list/document-verification/single-document")
    );
    const currentFilterModel = res?.api?.getFilterModel();
    let colState = gridRef?.current?.api?.getColumnState();
    const currentPage = gridRef?.current?.api?.paginationGetCurrentPage();
    const pageSize = gridRef?.current?.api?.paginationGetPageSize();
    let sortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch(setFilterModel(currentFilterModel));
    dispatch(setSortModel(sortState));
    dispatch(
      setPageModel({
        page: currentPage,
        pageSize: pageSize,
      })
    );
    if (dId) {
      let currentPage = res.api.paginationGetCurrentPage(); // Gets the current page index
      let pageSize = res.api.paginationGetPageSize(); // Gets the number of rows per page
      let isFilterActive = res.api.isAnyFilterPresent(); // Check if a filter is applied

      let filteredData;

      if (isFilterActive) {
        let arr = [];
        res.api.forEachNodeAfterFilterAndSort(function (node) {
          arr.push(node.data);
        });
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        filteredData = arr
          .slice(startIndex, endIndex)
          .map((node) => node?.documentId);
      } else {
        // If no filter is applied, process data based on pagination (current page)
        let startIndex = currentPage * pageSize;
        let endIndex = startIndex + pageSize;

        // Get all rows data
        const allRowsData = [];
        res.api.forEachNode((node) => {
          allRowsData.push(node.data);
        });

        // Slice data based on pagination
        filteredData = allRowsData
          .slice(startIndex, endIndex)
          .map((node) => node?.documentId);
      }

      dispatch(setLookUpInfo(lookUpData?.inputTypeList));
      dispatch(setdId(dId));
      dispatch(
        setFilteredData(
          filteredData.length > pageSize
            ? filteredData.slice(0, pageSize)
            : filteredData
        )
      );
    }
  }
  const { agGridColumns, agGridRows } = convertToAgGridFormat(rows, columns);
  // Create the new column definitions
  // const checkboxColumn = {
  //   headerCheckboxSelection: true, // Enables checkbox in the header to select all
  //   checkboxSelection: true, // Enables checkboxes for each row
  //   width: 100,
  //   sortable: false,
  //   filter: false,
  // };

  const iconButtonColumn = {
    field: "view",
    headerName: "View",
    width: 100,
    sortable: false,
    filter: false,
    cellRenderer: (params) => {
      if (params.node.group) {
        return null;
      }
      return (
        <IconButton
          onClick={() => btnViewClick(params?.data?.documentId, params)}
        >
          <VisibilityIcon />
        </IconButton>
      );
    },
  };

  // Use unshift to add these columns to the beginning of the array
  // agGridColumns.unshift(checkboxColumn, iconButtonColumn);
  agGridColumns.unshift(iconButtonColumn);

  function btnExportXlClick() {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: "Document Verification List",
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv({
        fileName: "Document Verification List",
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  const downloadCsv = (data) => {
    const convertToCSV = (objArray) => {
      const array = Array.isArray(objArray) ? objArray : JSON.parse(objArray);
      let str = "";

      // Get the headers
      const headers = Object.keys(array[0]);
      str += headers.join(",") + "\r\n";

      // Get the rows
      array.forEach((item) => {
        let line = "";
        headers.forEach((header, index) => {
          if (index > 0) line += ",";
          line += item[header];
        });
        str += line + "\r\n";
      });

      return str;
    };

    if (data.length > 0) {
      let csvData = convertToCSV(data);

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `ExportList_${dayjs().format("DDMMYYYY")}.csv`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      console.error("No data provided for export.");
    }
  };

  const btnExportListCsvClick = async (docIds) => {
    const payload = {
      commonParams,
      ConfigId: "0",
      ModuleId: "0",
      SectionId: "0",
      RecordId: docIds,
      RequestType: "1", // Assuming different RequestType for CSV export
    };

    setLoading(true);

    try {
      let res = await GetExportElementsApi(payload);

      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        downloadCsv(res?.data?.searchValues); // Assuming downloadCsv function exists
        setLoading(false);
        setValue(null);
      } else {
        logTelemetry(
          "failed",
          "DocumentVerification",
          "btnExportListCsvClick",
          `${res?.status?.message}`
        );
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "error",
        });
        setValue(null);
      }
    } catch (e) {
      console.error(e, "error");
      logTelemetry(
        "Failed",
        "DocumentVerification",
        "exportListCsvClick",
        e.message,
        e
      );
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "exportListCsvClick : " + e.message,
        type: "error",
      });
      setValue(null);
    }
  };

  const handleClose = (e, reason) => {
    if (e === "close") {
      setmOpen(false);
      return;
    }
    if (reason && reason == "backdropClick" && "escapeKeyDown") return;
    if (value) {
      // let docIds = gridRef.current?.api
      //   ?.getRenderedNodes()
      //   .filter((node) => node.data.inputType == value.value)
      //   .map((node) => node.data.documentId)
      //   .join("^");
      let docIds = agGridRows
        ?.filter((node) => node.inputType == value.value)
        ?.map((node) => node.documentId)
        ?.join("^");
      if (!docIds) {
        setNotify({
          isOpen: true,
          message: "No data available for the selected type.",
          type: "warning",
        });
      } else {
        if (type === "xl") {
          btnExtractElementsClick(docIds);
        } else if (type === "csv") {
          btnExportListCsvClick(docIds);
        }
        setmOpen(false);
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Please choose the type of data you would like to download.",
        type: "warning",
      });
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const onSelectionChanged = useCallback(() => {
    const selected = gridRef?.current?.api?.getSelectedRows();
    setSelectedRows(selected);
  }, []);

  // Memoize derived value from selectedRows
  const selectedRowIds = useMemo(
    () => selectedRows.map((row) => row.id),
    [selectedRows]
  );

  return (
    <div>
      <Grid container>
        <Grid item={true} xs={12} sm={12}>
          <Grid container>
            <Grid
              item={true}
              xs={12}
              sm={12}
              className="mb-3 mt-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "1rem 0",
              }}
            >
              <Typography variant="h6">Document Recognizer List</Typography>
              <Box>
                <>
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      dispatch(setActiveComponent("/DOCAICONFIGURATION"))
                    }
                    className="button-styles !mr-2"
                  >
                    DocAi Configuration
                  </Button> */}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpen("csv")}
                    className="button-styles !mr-2"
                  >
                    EXPORT DATA ELEMENTS(CSV)
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpen("xl")}
                    className="button-styles !mr-2"
                  >
                    EXPORT DATA ELEMENTS(XLS)
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={btnSingleClick}
                    className="button-styles !mr-2"
                  >
                    Upload Single
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={btnBatchClick}
                    className="button-styles"
                  >
                    Upload Batch
                  </Button>
                </>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <span>
            Showing records for the location: {formData?.location?.companyName}{" "}
            From: {dayjs(formData?.fromDate).format("MM/DD/YYYY")} To:{" "}
            {dayjs(formData?.toDate).format("MM/DD/YYYY")}. You may change
            Filters by clicking on filter Icon.
          </span>
          <Tooltip title="Apply Filter">
            <FilterListIcon
              onClick={() => btnFilterClick()}
              color="primary"
              className="secondaryIcon"
            />
          </Tooltip>
          <Tooltip title="Clear Filters">
            <ClearAllIcon
              placeholder="Clear All"
              onClick={() => btnRefreshClick()}
              color="primary"
              className="secondaryIcon"
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid gap={2} className="w-full flex justify-between mb-2 mt-4 md:mt-0">
        <div className="w-full">
          <Paper
            component="form"
            sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
          >
            {/* Clear button on the left if searchVal is not empty */}
            {formData?.searchVal && (
              <IconButton
                sx={{ p: "10px" }}
                aria-label="clear"
                onClick={() =>
                  dispatch(setFormData({ ...formData, searchVal: "" }))
                }
              >
                <ClearIcon />
              </IconButton>
            )}

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              value={formData?.searchVal}
              placeholder="Search by Batch Code or Document Id"
              id="Search by name or mobile number or email"
              name="Search by name or mobile number or email"
              onChange={(e) => {
                dispatch(
                  setFormData({
                    ...formData,
                    searchVal: e.target.value,
                  })
                );
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  getDocumentList(formData);
                  dispatch(setFiltersApplied(true));
                }
              }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="search-icon"
              onClick={(e) => {
                e.preventDefault();
                getDocumentList(formData);
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div>
          <Tooltip title="Refresh" placement="top" arrow>
            <MuiButton
              className={"filterButton"}
              onClick={() => btnRefreshClick()}
            >
              <RefreshIcon />
            </MuiButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Apply Filter" placement="top" arrow>
            <MuiButton
              className={"filterButton"}
              onClick={() => btnFilterClick()}
            >
              {filtersApplied ? (
                <Badge variant="dot" color="error">
                  <FilterAltIcon />
                </Badge>
              ) : (
                <FilterAltIcon />
              )}
            </MuiButton>
          </Tooltip>
        </div>
      </Grid>

      <div className="mt-3">
        <Box className="ag-theme-alpine docRecHgt relative">
          <div className="absolute z-10 top-[6px] right-[-2px]">
            <Button
              variant="outlined"
              color="primary"
              onClick={btnExportCsvClick}
              sx={{ marginRight: 1 }}
              className="button-styles"
            >
              export List(csv)
              <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={btnExportXlClick}
              sx={{ marginRight: 1 }}
              className="button-styles"
            >
              export List(xls)
              <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
            </Button>
          </div>
          <AgGridReact
            ref={gridRef}
            rowSelection={"multiple"}
            columnDefs={agGridColumns}
            onSelectionChanged={onSelectionChanged}
            rowData={agGridRows ?? []}
            defaultColDef={defaultColDef}
            rowGroupPanelShow="always"
            pagination={true}
            paginationPageSize={pageModel?.pageSize ?? 100}
            paginationPageSizeSelector={[100, 250, 500, 1000]}
            onGridReady={onGridReady}
            alwaysShowVerticalScroll={true}
            domLayout="autoHeight"
            animateRows={true}
            loading={isLoading}
            suppressCopyRowsToClipboard={true}
          />
        </Box>
      </div>
      <AutocompleteModal
        open={mopen}
        setOpen={setmOpen}
        handleClose={handleClose}
        handleOpen={() => {}}
        value={value}
        setValue={setValue}
        selectedOption={value}
        options={options}
        ddlDocumentTypeChange={setValuee}
        isFromMore={isFromMore}
      />

      {open ? (
        <Dialogs
          open={true}
          cancel={agree}
          submit={disagree}
          // page={page}
          setFilters={setFilters}
          filters={filters}
          // setIsFilters={setIsFilters}
          setOpen={setOpen}
          lookUpData={lookUpData}
          formData={formData}
          // setFormData={setFormData}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          handleSubmit={handleSubmit}
          subStatusData={subStatusData}
          handleClearValues={handleClearValues}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
      <ClearStatesConfirmationDialog
        onClose={() => handleClose1()}
        onConfirm={handleConfirm}
        open={dialogState.open}
        title={dialogState.title}
        subTitle={dialogState.subTitle}
        type={dialogState.type}
      />
    </div>
  );
};

export default DocumentVerification;

// <div>
// <Tooltip title="More" placement="top" arrow>
//   <MuiButton
//     className={"filterButton"}
//     aria-controls={open ? "account-menu" : undefined}
//     aria-haspopup="true"
//     aria-expanded={open ? "true" : undefined}
//     onClick={handleMenu}
//   >
//     <MoreVertIcon />
//   </MuiButton>
// </Tooltip>
// <Menu
//   anchorEl={anchorEl}
//   id="account-menu"
//   open={Boolean(anchorEl)}
//   onClose={handleMoreClose}
//   slotProps={{
//     paper: {
//       elevation: 0,
//       sx: {
//         overflow: "visible",
//         filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
//         mt: 1.5,
//         "& .MuiAvatar-root": {
//           width: 32,
//           height: 32,
//           ml: -0.5,
//           mr: 1,
//         },
//         "&::before": {
//           content: '""',
//           display: "block",
//           position: "absolute",
//           top: 0,
//           right: 14,
//           width: 10,
//           height: 10,
//           bgcolor: "background.paper",
//           transform: "translateY(-50%) rotate(45deg)",
//           zIndex: 0,
//         },
//       },
//     },
//   }}
//   transformOrigin={{ horizontal: "right", vertical: "top" }}
//   anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
// >
//   <MenuItem
//     onClick={() => {
//       console.log(selectedRows, " selectedRows ");
//       setIsFromMore(true);
//       handleMoreClose();
//       setmOpen(true);
//     }}
//   >
//     Assign
//   </MenuItem>
//   <MenuItem onClick={handleMoreClose}>

//     Un-Assign
//   </MenuItem>
// </Menu>
// </div>
