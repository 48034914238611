import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AutocompleteModal = ({
  open,
  setOpen,
  value,
  setValue,
  options,
  selectedOption,
  handleOpen,
  ddlDocumentTypeChange,
  handleClose,
  isFromMore,
}) => {
  const loginDetails = useSelector((state) => state.loginDetails);

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const createdByData = useMemo(() => {
    if (details?.aIStaffList && details.aIStaffList[0]?.code !== "0") {
      return [{ description: "All", code: "0" }, ...details.aIStaffList];
    }
    return details?.aIStaffList || [];
  }, [details]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="autocomplete-modal-title"
        aria-describedby="autocomplete-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={() => handleClose("close")}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          {isFromMore ? (
            <>
              <Autocomplete
                options={options || []}
                value={selectedOption}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                // onChange={ddlDocumentTypeChange}
                disableClearable
                name="Role"
                id="Role"
                className="mt-6 mb-3"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Role"
                    variant="outlined"
                    name="Role"
                    id="Role"
                    size="small"
                  />
                )}
              />
              <Autocomplete
                options={createdByData || []}
                value={selectedOption}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.description === value.description
                }
                // onChange={ddlDocumentTypeChange}
                disableClearable
                name="Assign To"
                id="Assign To"
                className="mt-6 mb-3"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assign To"
                    variant="outlined"
                    name="Assign To"
                    id="Assign To"
                    size="small"
                  />
                )}
              />

              <Button
                variant="outlined"
                className="button-styles !mt-3 !float-right"
                onClick={() => {}}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Autocomplete
                options={options || []}
                value={selectedOption}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                onChange={ddlDocumentTypeChange}
                disableClearable
                name="Select File Type"
                id="Select File Type"
                className="mt-6 mb-3"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select File Type"
                    variant="outlined"
                    name="Select File Type1"
                    id="Select File Type1"
                    size="small"
                  />
                )}
              />
              <Button
                variant="outlined"
                className="button-styles !mt-3 !float-right"
                onClick={handleClose}
              >
                Download
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AutocompleteModal;
