import React, { useContext, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import {
  Button as MuiButton,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  TextField,
  Divider,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Container,
} from "@mui/material";
import Breadcrumb from "../../components/Breadcrumb";
import {
  CaptureUploaddback,
  CaptureUploaddfront,
  ProceedCapturee,
} from "../../apis/captureUpload";
import { PayerIdentifierInfo } from "../../apis/payeridentification";
import MyContext from "../../components/MyContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import Notification from "../../components/Notification";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import { logTelemetry } from "../../logTelemetry";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../../redux/actions";
import {
  setcaptureData,
  setcaptureDetailsData,
  setcaptureSMSData,
} from "../../redux/actions/patientDetails";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CaptureDriverLicense = () => {
  debugger;
  const previousComponent = useSelector(
    (state) => state.previousComponent.previousComponent
  );
  const dispatch = useDispatch();

  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();
  const [frontImageId, setFrontImageId] = useState();
  const [backImageId, setBackImageId] = useState();
  const [frontImageFileName, setFrontImageFileName] = useState();
  const [backImageFileName, setBackImageFileName] = useState();
  const [showVerifbtn, setshowVerifbtn] = useState();
  // const { details?.companyId, setdetails?.companyId } = useContext(MyContext);
  const [showClearbtn, setshowClearbtn] = useState();
  const [extractbtn, setextractbtn] = useState();
  const [payerName, setpayerName] = useState("");
  const [payerId, setpayerId] = useState("");
  const [subscriberId, setsubscriberId] = useState("");
  const [groupNumber, setgroupNumber] = useState("");
  const [insuranceType, setinsuranceType] = useState("");
  const [insuranceNetwork, setinsuranceNetwork] = useState("");
  const [rxBin, setrxBin] = useState("");
  const [rxId, setrxId] = useState("");
  const [rxGrp, setrxGrp] = useState("");
  const [rxPcn, setrxPcn] = useState("");
  const [clearingHousePayerId, setclearingHousePayerId] = useState("");
  const [clearingHousePayerName, setclearingHousePayerName] = useState("");
  const [clientSystemPayerId, setclientSystemPayerId] = useState("");
  const [clientSystemPayerName, setclientSystemPayerName] = useState("");
  const [clientSystemPlanId, setclientSystemPlanId] = useState("");
  const [clientSystemPlanName, setclientSystemPlanName] = useState("");
  const [insuranceCardType, setinsuranceCardType] = useState("");
  const [notes, setnotes] = useState("");
  const [isTrue, setIsTrue] = React.useState(false);
  const [ismappingTrue, setismappingTrue] = React.useState(false);
  const [payerValidationStatus, setpayerValidationStatus] = useState("");
  const [subscriberIdValidationStatus, setsubscriberIdValidationStatus] =
    useState("");
  const [payerNameError, setpayerNameError] = useState(false);
  const [payerSubscriberIdError, setpayerSubscriberIdError] = useState(false);
  const [payerSSNError, setpayerSSNError] = useState(false);

  const [IdentifypayerName, setIdentifypayerName] = useState("");
  const [IdentifypayerId, setIdentifypayerId] = useState("");
  const [IdentifysubscriberId, setIdentifysubscriberId] = useState("");
  const [IdentifygroupNumber, setIdentifygroupNumber] = useState("");
  const [insuranceTypeNotes, setinsuranceTypeNotes] = useState("");
  const [IdentifySSN, setIdentifySSN] = useState("");
  const navigate = useNavigate();
  

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const textFieldStyles = {
    width: "100%",
    margin: "12px 12px",
  };

  const textCmbFieldStyles = {
    width: "92%",
    margin: "12px 12px",
  };

  const { data, mutateAsync, isLoading } = CaptureUploaddfront();
  const {
    data: backdata,
    mutateAsync: backMutateAsync,
    isLoading: backLoading,
  } = CaptureUploaddback();
  const {
    data: proceedData,
    mutateAsync: proceed,
    isLoading: proceedLoading,
  } = ProceedCapturee();

  const {
    data: identifyData,
    mutateAsync: identifyMutateAsync,
    isLoading: identifyLoading,
  } = PayerIdentifierInfo();

  // useEffect(() => {
  //   if (data?.status?.code == 200 && data?.data?.fileId != null) {
  //     setFrontImageId(data?.data?.fileId);
  //   }

  //   if (backdata?.status?.code == 200 && backdata?.data?.fileId != null) {
  //     setBackImageId(backdata?.data?.fileId);
  //   }

  //   if (data != undefined && data?.status?.code != 200) {
  //     console.log(' ===========> data 1');
  //     setNotify({
  //       isOpen: true,
  //       message: `${data?.message}`,
  //       type: 'error',
  //     });
  //   }
  //   if (backdata != undefined && backdata?.status?.code != 200) {
  //     console.log(' ===========> data 2');
  //     setNotify({
  //       isOpen: true,
  //       message: `${backdata?.message}`,
  //       type: 'error',
  //     });
  //   }
  //   if (proceedData != undefined && proceedData?.status?.code != 200) {
  //     console.log(' ===========> data 3');
  //     console.log('proceedData', proceedData);
  //     setNotify({
  //       isOpen: true,
  //       message: `${proceedData?.message}`,
  //       type: 'error',
  //     });
  //   }

  //   if (data) {
  //     if (data?.response && data?.response?.status != 200) {
  //       setNotify({
  //         isOpen: true,
  //         message: `${data?.message}`,
  //         type: 'error',
  //       });
  //     }
  //   }
  //   if (backdata) {
  //     if (backdata?.response && backdata?.response?.status != 200) {
  //       setNotify({
  //         isOpen: true,
  //         message: `${backdata?.message}`,
  //         type: 'error',
  //       });
  //     }
  //   }
  //   if (proceedData) {
  //     if (proceedData?.response && proceedData?.response?.status != 200) {
  //       setNotify({
  //         isOpen: true,
  //         message: `${proceedData?.message}`,
  //         type: 'error',
  //       });
  //     }
  //   }
  // }, [data, backdata, proceedData]);
  useEffect(() => {
    if (data?.status?.code == 200 && data?.data?.fileId != null) {
      setFrontImageId(data?.data?.fileId);
    }
    if (data != undefined && data?.status?.code != 200) {
      logTelemetry(
        "Failed",
        "CaptureDriverLicense",
        "useEffect",
        data?.status?.message
      );
      setNotify({
        isOpen: true,
        message: `${data?.status?.message}`,
        type: "error",
      });
    }
    if (data) {
      if (data?.response && data?.response?.status != 200) {
        logTelemetry(
          "Failed",
          "CaptureDriverLicense",
          "useEffect",
          data?.response?.message
        );
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (identifyData != undefined && identifyData?.status?.code == 200) {
      var locIdentifySSN = IdentifySSN;
      onClearIdentifyData();
      setpayerName(identifyData?.data?.payerName);
      setpayerId(identifyData?.data?.payerId);
      setsubscriberId(identifyData?.data?.subscriberId);
      setgroupNumber(identifyData?.data?.groupNumber);
      setinsuranceType(identifyData?.data?.insuranceType);
      setinsuranceNetwork(identifyData?.data?.insuranceNetwork);
      setrxBin(identifyData?.data?.rxBin);
      setrxPcn(identifyData?.data?.rxPcn);
      setrxGrp(identifyData?.data?.rxGrp);
      setrxId(identifyData?.data?.rxId);
      setinsuranceCardType(identifyData?.data?.insuranceCardType);
      setnotes(identifyData?.data?.notes);
      setclearingHousePayerId(identifyData?.data?.clearingHousePayerId);
      setclearingHousePayerName(identifyData?.data?.clearingHousePayerName);
      setclientSystemPayerId(identifyData?.data?.clientSystemPayerId);
      setclientSystemPayerName(identifyData?.data?.clientSystemPayerName);
      setclientSystemPlanId(identifyData?.data?.clientSystemPlanId);
      setclientSystemPlanName(identifyData?.data?.clientSystemPlanName);
      setpayerValidationStatus(identifyData?.data?.payerValidationStatus);
      setIdentifySSN(locIdentifySSN);
      setsubscriberIdValidationStatus(
        identifyData?.data?.subscriberIdValidationStatus
      );
      setismappingTrue(true);
    }
    if (identifyData != undefined && identifyData?.status?.code != 200) {
      onClearIdentifyData(1);
      // console.log(' ===========> data 2');
      logTelemetry(
        "Failed",
        "CaptureDriverLicense",
        "useEffect",
        identifyData?.status?.message
      );
      setNotify({
        isOpen: true,
        message: `${identifyData?.status?.message}`,
        type: "error",
      });
    }

    if (identifyData) {
      if (identifyData?.response && identifyData?.response?.status != 200) {
        logTelemetry(
          "Failed",
          "CaptureDriverLicense",
          "useEffect",
          identifyData?.response?.message
        );
        setNotify({
          isOpen: true,
          message: `${identifyData?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [identifyData]);

  useEffect(() => {
    if (backdata?.status?.code == 200 && backdata?.data?.fileId != null) {
      setBackImageId(backdata?.data?.fileId);
    }
    if (backdata != undefined && backdata?.status?.code != 200) {
      // console.log(' ===========> data 2');
      logTelemetry(
        "Failed",
        "CaptureDriverLicense",
        "useEffect",
        backdata?.status?.message
      );
      setNotify({
        isOpen: true,
        message: `${backdata?.status?.message}`,
        type: "error",
      });
    }

    if (backdata) {
      if (backdata?.response && backdata?.response?.status != 200) {
        logTelemetry(
          "Failed",
          "CaptureDriverLicense",
          "useEffect",
          data?.response?.message
        );
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [backdata]);

  useEffect(() => {
    setinsuranceTypeNotes("");
    if (proceedData != undefined && proceedData?.status?.code != 200) {
      if (
        proceedData?.data != null &&
        proceedData?.data != "" &&
        proceedData?.data?.notes != ""
      ) {
        setinsuranceTypeNotes(proceedData?.data?.notes);
      } else {
        setNotify({
          isOpen: true,
          message: `${proceedData?.status.message}`,
          type: "error",
        });
      }
    }
    if (proceedData != undefined && proceedData?.status?.code == 200) {
      dispatch(setcaptureDetailsData(null));
      dispatch(setcaptureData(JSON.stringify(proceedData)));
      setextractbtn(false);
      setshowClearbtn(true);
      setshowVerifbtn(true);
      setpayerName(proceedData?.data?.payerName);
      setpayerId(proceedData?.data?.payerId);
      setsubscriberId(proceedData?.data?.subscriberId);
      setgroupNumber(proceedData?.data?.groupNumber);
      setinsuranceType(proceedData?.data?.insuranceType);
      setinsuranceNetwork(proceedData?.data?.insuranceNetwork);
      setrxBin(proceedData?.data?.rxBin);
      setrxPcn(proceedData?.data?.rxPcn);
      setrxGrp(proceedData?.data?.rxGrp);
      setrxId(proceedData?.data?.rxId);
      setinsuranceCardType(proceedData?.data?.insuranceCardType);
      setnotes(proceedData?.data?.notes);
      setclearingHousePayerId(proceedData?.data?.clearingHousePayerId);
      setclearingHousePayerName(proceedData?.data?.clearingHousePayerName);
      setclientSystemPayerId(proceedData?.data?.clientSystemPayerId);
      setclientSystemPayerName(proceedData?.data?.clientSystemPayerName);
      setclientSystemPlanId(proceedData?.data?.clientSystemPlanId);
      setclientSystemPlanName(proceedData?.data?.clientSystemPlanName);
      setpayerValidationStatus(proceedData?.data?.payerValidationStatus);
      setsubscriberIdValidationStatus(
        proceedData?.data?.subscriberIdValidationStatus
      );
    }
    if (proceedData) {
      if (proceedData?.response && proceedData?.response?.status != 200) {
        setNotify({
          isOpen: true,
          message: `${proceedData?.response?.status.message}`,
          type: "error",
        });
      }
    }
  }, [proceedData]);

  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
    { label: "Upload Image", link: "/patient-list/capture-driving-license" },
  ];

  const handleIdentifyPayerNameChange = (event) => {
    setIdentifypayerName(event.target.value);
    if (isTrue == true) {
      onClearIdentifyData(1);
    }
  };

  const handleIdentifyPayerIdChange = (event) => {
    setIdentifypayerId(event.target.value);
  };

  const handleIdentifySubscriberChange = (event) => {
    setIdentifysubscriberId(event.target.value);
  };
  const handleIdentifySSNChange = (event) => {
    setIdentifySSN(event.target.value);
    if (!IdentifySSN) {
    } else {
      setpayerSubscriberIdError(false);
    }
  };

  const handleIdentifygroupNumberChange = (event) => {
    setIdentifygroupNumber(event.target.value);
  };

  const handlePayerNameChange = (event) => {
    setpayerName(event.target.value);
  };
  const handlePayerIdChange = (event) => {
    setpayerId(event.target.value);
  };

  const handleSubscriberChange = (event) => {
    setsubscriberId(event.target.value);
  };

  const handlegroupNumberChange = (event) => {
    setgroupNumber(event.target.value);
  };

  const handleinsuranceTypeChange = (event) => {
    setinsuranceType(event.target.value);
  };
  const handlenetworkTypeChange = (event) => {
    setinsuranceNetwork(event.target.value);
  };
  const handlerxidChange = (event) => {
    setrxId(event.target.value);
  };
  const handlerxGroupChange = (event) => {
    setrxGrp(event.target.value);
  };
  const handlerxBinChange = (event) => {
    setrxBin(event.target.value);
  };
  const handlerxPcnChange = (event) => {
    setrxPcn(event.target.value);
  };
  const handlerNotesChange = (event) => {
    setnotes(event.target.value);
  };

  const frontImageHandler = async (e) => {
    let values = {
      mimeType: "image/jpg",
      fileName: e.target?.files[0].name,
      locationNumber: "",
    };
    setFrontImageFileName(values.fileName);
    try {
      var fileReader = new FileReader();
      fileReader.onload = async function (event) {
        try {
          var base64 = event.target.result;
          values.file = e.target?.files[0];
          values.locationNumber =
            UpdateLocationValue == undefined
              ? details?.companyId
              : UpdateLocationValue;
          setFrontImage(base64);
          setextractbtn(true);
          await mutateAsync(values);
        } catch (ex) {
          // toast.error(ex);
          logTelemetry(
            "Failed",
            "CaptureDriverLicense",
            "frontImageHandler",
            ex?.message,
            ex
          );
          setNotify({
            isOpen: true,
            message: `${ex}`,
            type: "error",
          });
        }
      };
      fileReader.readAsDataURL(e.target?.files[0]);
    } catch (error) {
      // toast.error(error);
      setNotify({
        isOpen: true,
        message: `${error.message}`,
        type: "error",
      });
    }
  };

  const backImageHandler = async (e) => {
    let values = {
      mimeType: "image/jpg",
      fileName: e.target?.files[0].name,
      locationNumber: "",
    };
    setBackImageFileName(values.fileName);
    try {
      var fileReader = new FileReader();
      fileReader.onload = async function (event) {
        try {
          var base64 = event.target.result;
          values.file = e.target?.files[0];
          values.locationNumber =
            UpdateLocationValue == undefined
              ? details?.companyId
              : UpdateLocationValue;
          setBackImage(base64);
          await backMutateAsync(values);
        } catch (ex) {
          // toast.error(ex)
          logTelemetry(
            "Failed",
            "CaptureDriverLicense",
            "backImageHandler",
            ex?.message,
            ex
          );
          setNotify({
            isOpen: true,
            message: `${ex}`,
            type: "error",
          });
        }
      };
      fileReader.readAsDataURL(e.target?.files[0]);
    } catch (error) {
      // toast.error(error);
      setNotify({
        isOpen: true,
        message: `${error.message}`,
        type: "error",
      });
    }
  };

  const updateLocationItem = (e) => {
    // const existingData = JSON.parse(sessionStorage.getItem("details")) || {};
    let name = e.target.innerHTML;
    let id = details.aILocations.filter((i) => i.companyName == name);
    setUpdateLocationName(id[0]?.companyName);
    setUpdateLocationValue(id[0]?.companyId);
  };
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null; 
  }, [loginDetails]);

  let locationData = details?.aILocations.map((i) => i.companyName);
  let companyId =
    details?.companyId != undefined ? details?.companyId : details.CompanyId;
  companyId = details.aILocations.filter((i) => i.companyId == companyId);
  const [UpdateLocationValue, setUpdateLocationValue] = useState(
    companyId[0]?.companyId
  );
  const [UpdateLocationName, setUpdateLocationName] = useState(
    companyId[0]?.companyName
  );

  const onClearIdentifyData = async (value) => {
    if (value == 1) {
      setismappingTrue(false);
    }
    setpayerName("");
    setsubscriberId("");
    setpayerId("");
    setgroupNumber("");
    setinsuranceType("");
    setinsuranceNetwork("");
    setrxBin("");
    setrxPcn("");
    setrxGrp("");
    setrxId("");
    setinsuranceCardType("");
    setnotes("");
    setclearingHousePayerId("");
    setclearingHousePayerName("");
    setclientSystemPayerId("");
    setclientSystemPayerName("");
    setclientSystemPlanId("");
    setclientSystemPlanName("");
    setpayerValidationStatus("");
    setsubscriberIdValidationStatus("");
    setshowVerifbtn(false);
  };

  const onClearHandler = async () => {
    setextractbtn(false);
    setshowClearbtn(false);
    setshowVerifbtn(false);
    setFrontImage(undefined);
    setBackImage(undefined);
    sessionStorage.removeItem("captureData");
    setpayerName("");
    setpayerId("");
    setsubscriberId("");
    setgroupNumber("");
    setinsuranceType("");
    setinsuranceNetwork("");
    setrxBin("");
    setrxPcn("");
    setrxGrp("");
    setrxId("");
    setinsuranceCardType("");
    setpayerValidationStatus("");
    setsubscriberIdValidationStatus("");
    setnotes("");
    setclearingHousePayerId("");
    setclearingHousePayerName("");
    setIsTrue(false);
    setismappingTrue(false);
    setclientSystemPayerId("");
    setclientSystemPayerName("");
    setclientSystemPlanId("");
    setclientSystemPlanName("");
    setpayerValidationStatus("");
    setsubscriberIdValidationStatus("");
    setIdentifygroupNumber("");
    setIdentifysubscriberId("");
    setIdentifypayerId("");
    setIdentifypayerName("");
    setIdentifySSN("");
  };
  const onIdentifyClearHandler = async () => {
    setextractbtn(false);
    setshowClearbtn(false);
    setshowVerifbtn(false);
    setFrontImage(undefined);
    setBackImage(undefined);
    sessionStorage.removeItem("captureData");
    setpayerName("");
    setpayerId("");
    setsubscriberId("");
    setgroupNumber("");
    setinsuranceType("");
    setinsuranceNetwork("");
    setrxBin("");
    setrxPcn("");
    setrxGrp("");
    setrxId("");
    setinsuranceCardType("");
    setpayerValidationStatus("");
    setsubscriberIdValidationStatus("");
    setnotes("");
    setclearingHousePayerId("");
    setclearingHousePayerName("");
    setismappingTrue(false);
    setIdentifygroupNumber("");
    setIdentifysubscriberId("");
    setIdentifypayerId("");
    setIdentifypayerName("");
    setIdentifySSN("");
  };

  const onClearDeleteHandler = async () => {
    setshowClearbtn(false);
    setshowVerifbtn(false);
    sessionStorage.removeItem("captureData");
    setpayerName("");
    setpayerId("");
    setsubscriberId("");
    setgroupNumber("");
    setinsuranceType("");
    setinsuranceNetwork("");
    setrxBin("");
    setrxPcn("");
    setrxGrp("");
    setrxId("");
    setinsuranceCardType("");
    setpayerValidationStatus("");
    setsubscriberIdValidationStatus("");
    setnotes("");
    setclearingHousePayerId("");
    setclearingHousePayerName("");
  };

  const onVerifyHandler = async () => {
    var locatioObjectDetails = {
      locationName: UpdateLocationName,
      locationvalue: UpdateLocationValue,
    };
    sessionStorage.setItem(
      "locationDetails",
      JSON.stringify(locatioObjectDetails)
    );
    dispatch(
      setActiveComponent("/patient-list/capture-driving-license/patient-info")
    );
  };

  const onIdentifyVerifyHandler = async () => {
    var locatioObjectDetails = {
      locationName: UpdateLocationName,
      locationvalue: UpdateLocationValue,
    };
    sessionStorage.setItem(
      "locationDetails",
      JSON.stringify(locatioObjectDetails)
    );
    var captureDetailsData = {};
    captureDetailsData.insuranceCardFiles = [];
    captureDetailsData.patientInsurance = {};
    captureDetailsData.eligibilityServiceTypes = "";
    captureDetailsData.recordSource = "No Card";

    captureDetailsData.id = "";
    captureDetailsData.patientInsurance.subscriberId = subscriberId;
    captureDetailsData.patientInsurance.insuranceNetwork = insuranceNetwork;
    captureDetailsData.patientInsurance.payerName = payerName;
    captureDetailsData.patientInsurance.payerId = payerId;
    captureDetailsData.patientInsurance.groupNumber = groupNumber;
    captureDetailsData.patientInsurance.insuranceType = insuranceType;
    captureDetailsData.patientInsurance.ssn = IdentifySSN;
    captureDetailsData.patientInsurance.orbitPayerId =
      identifyData?.data?.orbitPayerId;
    captureDetailsData.patientInsurance.rxBin = "";
    captureDetailsData.patientInsurance.rxId = "";
    captureDetailsData.patientInsurance.rxPcn = "";
    captureDetailsData.patientInsurance.rxGrp = "";
    
    dispatch(setcaptureDetailsData(JSON.stringify(captureDetailsData)));
    dispatch(setcaptureData(null));
    dispatch(
      setActiveComponent("/patient-list/capture-driving-license/patient-info")
    );
    // window.location.href = "/patient-list/capture-driving-license/patient-info";
  };

  const onpayerIdentifyHandler = async () => {
    setpayerNameError(false);
    setpayerSubscriberIdError(false);
    setpayerSSNError(false);
    if (!IdentifypayerName) {
      // toast.error("Mobile or Email is required");
      setpayerNameError(true);
      if (!IdentifysubscriberId && !IdentifySSN) {
        setpayerSubscriberIdError(true);
        setpayerSSNError(true);
      }

      return;
    }
    if (!IdentifysubscriberId && !IdentifySSN) {
      setpayerSubscriberIdError(true);
      setpayerSSNError(true);
      return;
    }

    setismappingTrue(false);
    let values = {
      locationNumber:
        UpdateLocationValue == undefined
          ? details?.companyId
          : UpdateLocationValue,
      subscriberId: IdentifysubscriberId,
      payerName: IdentifypayerName,
      payerId: IdentifypayerId,
      insuranceType: "",
      groupNumber: IdentifygroupNumber,
      insuranceNetwork: "",
      ssn: IdentifySSN,
    };
    try {
      await identifyMutateAsync(values);
    } catch (ex) {
      logTelemetry(
        "Failed",
        "CaptureDriverLicense",
        "onPayerIdentifyHandler",
        ex?.message,
        ex
      );
    }
    //     sessionStorage.setItem("captureDetailsData",JSON.stringify(captureDetailsData));
    //  window.location.href = "/patient-list/capture-driving-license/patient-info";
  };

  const onSubmitHandler = async () => {
    var imageArray = [];
    if (frontImageId != undefined && backImageId != undefined) {
      imageArray = [
        {
          fileId: frontImageId,
          fileName: "BCBS_Front.jpg",
          fileExt: "jpeg",
          fileSize: null,
          width: null,
          height: null,
          dpi: null,
          ocrQuality: null,
          cardFace: null,
          imageCropMethod: null,
          ImageCaptureMethod: null,
          ImageCropped: false,
        },
        {
          fileId: backImageId == undefined ? null : backImageId,
          fileName: "BCBS_Back.jpg",
          fileExt: "jpeg",
          fileSize: 97334,
          width: 0,
          height: 0,
          dpi: 300,
          ocrQuality: 95,
          cardFace: null,
          imageCropMethod: null,
          ImageCaptureMethod: null,
          ImageCropped: false,
        },
      ];
    } else {
      imageArray = [
        {
          fileId: frontImageId == undefined ? backImageId : frontImageId,
          fileName: "BCBS_Front.jpg",
          fileExt: "jpeg",
          fileSize: null,
          width: null,
          height: null,
          dpi: null,
          ocrQuality: null,
          cardFace: null,
          imageCropMethod: null,
          ImageCaptureMethod: null,
          ImageCropped: false,
        },
      ];
    }
    let values = {
      commonParams: {
        organizationID: details?.orgId,
        companyID: UpdateLocationValue,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      },
      ocrDto: {
        referenceId: "",
        enableSqlUpdates: "True",
        locationNumber:
          UpdateLocationValue == undefined
            ? details?.companyId
            : UpdateLocationValue,
        batchId: "",
        files: imageArray,
      },
    };

    if (!frontImage && !backImage) {
      setNotify({
        isOpen: true,
        message: "Upload front and back insurance images",
        type: "error",
      });
    } else {
      try {
        await proceed(values);
      } catch (ex) {
        logTelemetry(
          "Failed",
          "CaptureDriverLicense",
          "onSubmitHandler",
          ex?.message,
          ex
        );
      }
    }
  };

  const handleBackButtonClick = () => {
    try {
      if (previousComponent) {
        // dispatch(setPreviousComponent(activeComponent));
        dispatch(setActiveComponent(previousComponent));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Grid
          container
          className="mt-3 mb-2 flex items-center"
          justifyContent="space-between"
        >
          <Grid item xs={6} sm={6}>
            <Breadcrumb items={breadcrumbItems} isRedux={true} />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            {showClearbtn && (
              <>
                <Grid item xs={2} sm={2} className="m-3 mb-2">
                  <div className="my-4 md:my-0">
                    <Button
                      text="Clear"
                      classname={"button-styles btnHeight"}
                      filled={true}
                      onclick={onClearHandler}
                    />
                  </div>
                </Grid>
                <Grid item xs={2} sm={2} sx={{ pl: 2 }} className="mt-3 mb-2">
                  <div className="my-4 md:my-0">
                    {details?.aiAccessRights?.verifyData == 1 && (
                      <Button
                        text="Verify"
                        classname={"button-styles btnHeight"}
                        filled={true}
                        onclick={onVerifyHandler}
                      />
                    )}
                  </div>
                </Grid>
              </>
            )}
            {extractbtn && (
              <Grid item xs={2} sm={2} className="mt-3 mb-2">
                <div className="my-4 md:my-0">
                  <Button
                    text="Extract"
                    classname={"button-styles btnHeight"}
                    filled={true}
                    onclick={onSubmitHandler}
                  />
                </div>
              </Grid>
            )}
            {isTrue && (
              <Grid item xs={2} sm={2} sx={{ pl: 2 }} className="mt-3 mb-2">
                <div className="my-4 md:my-0">
                  {ismappingTrue == true && (
                    <Button
                      text="Verify"
                      classname={"button-styles btnHeight"}
                      filled={true}
                      onclick={onIdentifyVerifyHandler}
                    />
                  )}
                </div>
              </Grid>
            )}
            {(ismappingTrue || isTrue || showClearbtn || extractbtn) && (
              <Grid item xs={2} sm={2} className="mt-3 mb-2" sx={{ pl: 1 }}>
                <Button
                  text="Back"
                  classname={"button-styles"}
                  style={{ float: "right" }}
                  filled={true}
                  // onclick={() => navigate(-1)}
                  onclick={handleBackButtonClick}
                />
              </Grid>
            )}
            {!ismappingTrue && !isTrue && !showClearbtn && !extractbtn && (
              <Grid item xs={2} sm={2} className="mt-3 mb-2">
                <Button
                  text="Back"
                  classname={"button-styles"}
                  filled={true}
                  // onclick={() => navigate(-1)}
                  onclick={handleBackButtonClick}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Card className="imgwidthCard">
            <CardContent>
              <Grid container className="pt-5">
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    sx={{ pl: 3 }}
                    id="combo-box-demo"
                    options={locationData}
                    size="small"
                    style={textCmbFieldStyles}
                    value={UpdateLocationName}
                    onChange={updateLocationItem}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Location" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {" "}
                </Grid>
                {!isTrue && (
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <div className="rounded-sm h-52 mx-6 md:mx-10 border border-gray-500 hover:border-blue-900">
                        <div className="h-full flex justify-center items-center image-container">
                          {frontImage == undefined ? (
                            <div>
                              <MuiButton
                                id="idMuiBtn"
                                className="uploadButton"
                                component="label"
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                                onChange={frontImageHandler}
                              >
                                Upload file
                                <VisuallyHiddenInput
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                />
                              </MuiButton>
                              <div className="flex justify-center">
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: 14, alignItems: "center" }}
                                  color="text.secondary"
                                  mt={1}
                                >
                                  Insurance front image
                                </Typography>
                              </div>
                            </div>
                          ) : (
                            // <div className="truncate object-cover imagee">
                            <img src={frontImage} alt="Front Image" />
                            // </div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-end mx-6 md:mx-10 my-2">
                        {frontImage != undefined ? (
                          <DeleteIcon
                            onClick={() => {
                              setFrontImageId(undefined);
                              setFrontImage(undefined);
                              setextractbtn(false);
                              onClearDeleteHandler();
                              setinsuranceTypeNotes("");
                            }}
                          />
                        ) : null}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="rounded-sm h-52 mx-6 mt-10 md:mt-0 border border-gray-500 hover:border-blue-900">
                        <div className="h-full flex justify-center items-center image-container">
                          {backImage == undefined ? (
                            <div>
                              <MuiButton
                                className="uploadButton"
                                component="label"
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                                onChange={backImageHandler}
                              >
                                Upload file
                                <VisuallyHiddenInput
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                />
                              </MuiButton>
                              <div className="flex justify-center">
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: 14, alignItems: "center" }}
                                  color="text.secondary"
                                  mt={1}
                                >
                                  Insurance back image
                                </Typography>
                              </div>
                            </div>
                          ) : (
                            // <div className="truncate object-cover">
                            <img src={backImage} alt="back Image" />
                            // </div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-end mx-6 my-2">
                        {backImage != undefined ? (
                          <DeleteIcon
                            onClick={() => {
                              setBackImageId(undefined);
                              setBackImage(undefined);
                              onClearDeleteHandler();
                              setinsuranceTypeNotes("");
                            }}
                          />
                        ) : null}
                      </div>
                    </Grid>{" "}
                  </Grid>
                )}

                {/* <Grid xs={12} sm={12}> */}
                <Grid container sx={{ paddingLeft: "20px" }}>
                  {insuranceTypeNotes?.length == 0 && (
                    <Grid item xs={12} sm={12} sx={{ background: "aliceblue" }}>
                      {" "}
                      <FormControlLabel
                        sx={{
                          paddingTop: "10px",
                          paddingLeft: "15px",
                          fontSize: "18px",
                        }}
                        color="primary"
                        control={<Checkbox name="check me" />}
                        checked={isTrue}
                        disabled={extractbtn == true ? true : false}
                        onChange={(e) => {
                          onIdentifyClearHandler();
                          setIsTrue(e.target.checked);
                        }}
                        label="I don't have insurance card will manually enter insurance details"
                      />{" "}
                    </Grid>
                  )}
                  {insuranceTypeNotes?.length > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Alert severity="info">
                        {/* <AlertTitle>Info</AlertTitle> */}
                        <strong style={{ fontSize: "18px", color: "red" }}>
                          {insuranceTypeNotes}
                        </strong>
                      </Alert>
                      {/* <Alert variant="outlined" severity="info">
{insuranceTypeNotes}
</Alert> */}
                    </Grid>
                    //  <Grid xs={12} sm={12} sx={{background:"aliceblue",paddingTop:"10px",paddingLeft:"15px",fontSize:"20px",color:"red"}}>
                    // {insuranceTypeNotes}
                    //  </Grid>
                  )}
                </Grid>
                {/* </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {isTrue && (
          <Card sx={{ mt: 2 }} className="imgwidthCard">
            <CardContent>
              <Grid container>
                <Grid
                  container
                  sx={{ pb: 1 }}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item xs={4} sm={4} sx={{ pr: 1 }}>
                    {" "}
                    <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                      Enter Insurance Details
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={2}></Grid>
                  <Grid item xs={4} sm={2}></Grid>
                  <Grid item xs={4} sm={2}>
                    <div className="my-4 md:my-0">
                      <Button
                        text="Clear"
                        // navigate="/capture/capture-driving-license/patient-info"
                        classname={"button-styles btnHeight"}
                        filled={true}
                        onclick={onIdentifyClearHandler}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={2} sx={{ pl: 1 }}>
                    <div>
                      <Button
                        text="Identify Payer"
                        // navigate="/capture/capture-driving-license/patient-info"
                        classname={"button-styles btnHeight"}
                        filled={true}
                        onclick={onpayerIdentifyHandler}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  borderRadius: "10px",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    autoFocus
                    id="outlined-password-input1"
                    label="Payer Name"
                    name="Payer Name"
                    autocomplete="off"
                    value={IdentifypayerName}
                    onChange={handleIdentifyPayerNameChange}
                    error={
                      payerNameError == true
                        ? IdentifypayerName == ""
                          ? true
                          : false
                        : false
                    }
                    className="m-0"
                    size="small"
                    sx={{ background: "white" }}
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-password-input2"
                    label="Payer Id"
                    name="Payer Id"
                    autocomplete="off"
                    onChange={handleIdentifyPayerIdChange}
                    value={IdentifypayerId}
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={6} sm={3} className="flex justify-center">
                  <TextField
                    id="outlined-password-input3"
                    label="Subscriber/ Member ID"
                    name="Subscriber/ Member ID"
                    autocomplete="off"
                    error={
                      payerSubscriberIdError == true
                        ? IdentifysubscriberId == ""
                          ? true
                          : false
                        : false
                    }
                    onChange={handleIdentifySubscriberChange}
                    value={IdentifysubscriberId}
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    style={textFieldStyles}
                  />{" "}
                  <span style={{ marginTop: "20px" }}>(OR)</span>
                </Grid>
                <Grid item xs={6} sm={3} className="flex justify-center">
                  <TextField
                    id="outlined-ssn-input"
                    label="Social Security No"
                    name="ssn"
                    autocomplete="off"
                    error={
                      payerSSNError == true
                        ? IdentifySSN == ""
                          ? IdentifysubscriberId == ""
                            ? true
                            : false
                          : false
                        : false
                    }
                    onChange={handleIdentifySSNChange}
                    value={IdentifySSN}
                    onKeyDown={(e) => {
                      var val = e.target.value.replace(/\D/g, "");
                      var newVal = "";
                      if (val.length > 4) {
                        e.target.value = val;
                      }
                      if (val.length > 3 && val.length < 6) {
                        newVal += val.substr(0, 3) + "-";
                        val = val.substr(3);
                      }
                      if (val.length > 5) {
                        newVal += val.substr(0, 3) + "-";
                        newVal += val.substr(3, 2) + "-";
                        val = val.substr(5);
                      }
                      newVal += val;
                      e.target.value = newVal.substring(0, 11);
                    }}
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-password-inpu4"
                    label="Group Number"
                    name="Group Number"
                    autocomplete="off"
                    sx={{ background: "white" }}
                    value={IdentifygroupNumber}
                    onChange={handleIdentifygroupNumberChange}
                    className="m-0"
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {(showVerifbtn || ismappingTrue == true) && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              {showVerifbtn && (
                <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                  Insurance Details
                </Typography>
              )}
              {isTrue && (
                <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                  Insurance payer Identified{" "}
                </Typography>
              )}
              <Divider />
              <Grid container>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-password-input1"
                    label="Payer Name"
                    name="Payer Name"
                    autocomplete="off"
                    value={payerName}
                    onChange={handlePayerNameChange}
                    className="m-0"
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-password-input2"
                    label="Payer Id"
                    autocomplete="off"
                    name="Payer Id"
                    onChange={handlePayerIdChange}
                    value={payerId}
                    className="m-0"
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-password-input3"
                    label="Subscriber/ Member ID"
                    name="Subscriber/ Member ID"
                    onChange={handleSubscriberChange}
                    value={subscriberId}
                    className="m-0"
                    autocomplete="off"
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-password-inpu4"
                    label="Group Number"
                    name="Group Number"
                    value={groupNumber}
                    onChange={handlegroupNumberChange}
                    className="m-0"
                    size="small"
                    autocomplete="off"
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-password-input5"
                    label="Insurance Type"
                    name="Insurance Type"
                    onChange={handleinsuranceTypeChange}
                    value={insuranceType}
                    className="m-0"
                    size="small"
                    autocomplete="off"
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-password-input7"
                    label="Network Type"
                    name="Network Type"
                    value={insuranceNetwork}
                    onChange={handlenetworkTypeChange}
                    className="m-0"
                    size="small"
                    autocomplete="off"
                    style={textFieldStyles}
                  />
                </Grid>
                {!isTrue && (
                  <Grid container>
                    <Grid item xs={12} sm={6} className="flex justify-center">
                      <TextField
                        id="outlined-password-input8"
                        label="Rx ID"
                        name="Rx ID"
                        value={rxId}
                        className="m-0"
                        size="small"
                        autocomplete="off"
                        style={textFieldStyles}
                        onChange={handlerxidChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="flex justify-center">
                      <TextField
                        id="outlined-password-input9"
                        label="Rx Bin"
                        name="Rx Bin"
                        value={rxBin}
                        className="m-0"
                        size="small"
                        autocomplete="off"
                        style={textFieldStyles}
                        onChange={handlerxBinChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="flex justify-center">
                      <TextField
                        id="outlined-password-inpu10t"
                        label="Rx PCN"
                        name="Rx PCN"
                        value={rxPcn}
                        className="m-0"
                        size="small"
                        autocomplete="off"
                        style={textFieldStyles}
                        onChange={handlerxPcnChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="flex justify-center">
                      <TextField
                        id="outlined-password-input12"
                        label="Rx Group"
                        name="Rx Group"
                        value={rxGrp}
                        className="m-0"
                        size="small"
                        autocomplete="off"
                        style={textFieldStyles}
                        onChange={handlerxGroupChange}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="outlined-password-input12"
                    label="Insurance Card Type "
                    name="Insurance Card Type "
                    value={insuranceCardType}
                    className="m-0"
                    size="small"
                    style={textFieldStyles}
                    autocomplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="outlined-password-inputtextarea12"
                    label="Notes"
                    multiline
                    name="Insurance Card Type "
                    value={notes}
                    className="m-0"
                    size="small"
                    autocomplete="off"
                    style={textFieldStyles}
                    onChange={handlerNotesChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={true}
                    id="outlined-PVS-input12"
                    label="Payer Validation Status"
                    name="Payer Validation Status"
                    value={payerValidationStatus}
                    className="m-0"
                    size="small"
                    autocomplete="off"
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={true}
                    id="outlined-SVSpassword-inputtextarea12"
                    label="SubscriberId Validation Status"
                    name="SubscriberId Validation Status"
                    value={subscriberIdValidationStatus}
                    className="m-0"
                    autocomplete="off"
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {(showVerifbtn || ismappingTrue) && (
          <Grid container>
            <Card sx={{ mt: 2, mb: 2 }} className="imgwidthCard">
              <CardContent>
                <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                  EMR/PM System Insurance Details
                </Typography>
                <Divider />
                <Grid container>
                  <Grid item xs={12} sm={6} className="flex justify-center">
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="outlined-password-input"
                      label="Payer Id"
                      name="Payer Id"
                      value={clientSystemPayerId}
                      className="m-0"
                      size="small"
                      autocomplete="off"
                      style={textFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="flex justify-center">
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="outlined-password-input"
                      label="Payer Name"
                      name="Payer Name"
                      value={clientSystemPayerName}
                      className="m-0"
                      size="small"
                      autocomplete="off"
                      style={textFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="flex justify-center">
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="outlined-password-input"
                      label="Plan ID"
                      name="Plan ID"
                      value={clientSystemPlanId}
                      className="m-0"
                      size="small"
                      autocomplete="off"
                      style={textFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="flex justify-center">
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="outlined-password-input"
                      label="Plan Name"
                      name="Plan Name"
                      value={clientSystemPlanName}
                      className="m-0"
                      autocomplete="off"
                      size="small"
                      style={textFieldStyles}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Container>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || proceedLoading || backLoading || identifyLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default CaptureDriverLicense;
