import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Autocomplete,
  Backdrop,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Container,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Breadcrumb from "../../components/Breadcrumb";
import { useFormik } from "formik";
import * as yup from "yup";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MyContext from "../../components/MyContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext } from "react";
import { baseurl } from "../../apis/Urls";
import { SendSMSAPI } from "../../apis/Urls";
import { instance } from "../../apis/axios";
import { InsuranceDiscoveryDetails } from "../../apis/discoverinsurance";
import Notification from "../../components/Notification";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { logTelemetry } from "../../logTelemetry";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../../redux/actions";
const textFieldStyles = {
  width: "100%",
  margin: "12px 12px",
};

const DiscoverInsurance = () => {
  debugger;
  const previousComponent = useSelector(
    (state) => state.previousComponent.previousComponent
  );
  // const details = useSelector((state) => JSON.parse(state.loginDetails));
  const dispatch = useDispatch();

  const loginDetails = useSelector((state) => state.loginDetails);

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null; 
  }, [loginDetails]);

  const [numberCheck, setNumberCheck] = useState(true);
  // const { details?.companyId, setdetails?.companyId } = useContext(MyContext);
  const [expandedsum, setExpandedsum] = useState(true);
  const [expandedsumMul, setexpandedsumMul] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [dateOfBirthError, setdateOfBirthError] = useState(false);
  const [addressLine1Error, setaddressLine1Error] = useState(false);
  const [genderError, setgenderError] = useState(false);
  const [cityError, setcityError] = useState(false);
  const [stateError, setstateError] = useState(false);
  const [postalCodeError, setpostalCodeError] = useState(false);
  const navigate = useNavigate();

  const { data, mutateAsync, isLoading, isError } = InsuranceDiscoveryDetails();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  let num_reg_ex =
    /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/;
  const [loading, setLoading] = useState(false);
  let navigation = useNavigate();
  const maxLength = 10;

  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
    { label: "Insurance Discovery", link: "/patient-list/send-sms-or-email" },
  ];

  // let details = JSON.parse(sessionStorage?.getItem("details"));
  let locationData = details?.aILocations.map((i) => i.companyName);

  let companyId =
    details?.companyId != undefined ? details?.companyId : details.CompanyId;
  companyId = details?.aILocations.filter((i) => i.companyId == companyId);
  const [UpdateLocationValue, setUpdateLocationValue] = useState(
    companyId[0]?.companyId
  );
  const [UpdateLocationName, setUpdateLocationName] = useState(
    companyId[0]?.companyName
  );

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const updateLocationItem = (e) => {
    // const existingData = JSON.parse(sessionStorage.getItem("details")) || {};
    let name = e.target.innerHTML;
    let id = details.aILocations.filter((i) => i.companyName == name);
    setFieldValue("companyName", name);
    setUpdateLocationName(id[0]?.companyName);
    setUpdateLocationValue(id[0]?.companyId);
  };

  const handleChangeDetails = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangesum = (panel) => (event, isExpandedsum) => {
    setExpandedsum(isExpandedsum ? panel : false);
  };

  const handleNumberInput = (event) => {
    if (event.key !== "Backspace" && !num_reg_ex.test(event.key)) {
      event.preventDefault();
    }
  };

  const MultihandleChange = (panel) => (event, isExpandedsum) => {
    setexpandedsumMul(isExpandedsum ? panel : false);
  };

  const handleSubmitClear = async () => {};

  function BindInsuranceDetails(val, subType) {}

  // const numberrr = (e) => {
  //   if (e.length > maxLength) {
  //     return `(${e[0]}${e[1]}${e[2]}) ${e[3]}${e[4]}${e[5]}-${e[6]}${e[7]}${e[8]}${e[9]}`;
  //   }
  // };

  // const mobileNumberFormat = (e) => {
  //   const newValue = e.target.value;
  //   if (newValue.length <= maxLength) {
  //     setFieldValue("mobileNumber", numberrr(newValue));
  //   }
  // };

  let ValidateError = {
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    gender: false,
    addressLine1: false,
    addressLine2: false,
    city: false,
    state: false,
    postalCode: false,
  };

  let schema = yup.object({
    location: yup.string().trim(),
    mobileNumber: yup.string().trim(),
    email: yup.string().email("E-Mail is not valid").trim(),
    firstName: yup.string().trim(),
    lastName: yup.string().trim(),
    dateOfBirth: yup.string(),
    gender: yup.string().nullable(),
    ssn: yup.string().nullable(),
  });

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      location: details?.companyId,
      mobileNumber: "",
      email: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      gender: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      ssn: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      setfirstNameError(false);
      setlastNameError(false);
      setdateOfBirthError(false);
      setaddressLine1Error(false);
      setgenderError(false);
      setcityError(false);
      setstateError(false);
      setpostalCodeError(false);
      var i = 0;
      if (values.firstName == "") {
        i = i + 1;
        setfirstNameError(true);
      }
      if (values.lastName == "") {
        i = i + 1;
        setlastNameError(true);
      }
      if (values.dateOfBirth == "") {
        i = i + 1;
        setdateOfBirthError(true);
      }
      // if(values.gender=="")
      // { i=i+1;
      //   setgenderError(true);
      // }
      if (values.addressLine1 == "") {
        i = i + 1;
        setaddressLine1Error(true);
      }
      if (values.state == "") {
        i = i + 1;
        setstateError(true);
      }
      if (values.city == "") {
        i = i + 1;
        setcityError(true);
      }
      if (values.postalCode == "") {
        i = i + 1;
        setpostalCodeError(true);
      }
      if (values.gender == null) {
        values.gender = "";
      }
      if (i > 0) {
        return;
      }
      try {
        debugger;
        await mutateAsync({
          commonParams: {
            organizationID: details?.orgId,
            companyID:
              UpdateLocationValue == null
                ? details?.companyId
                : UpdateLocationValue,
            coCode: details?.coCode,
            groupID: details?.groupID,
            timeZone: details?.timeZone,
            sessionId: details?.sesionId,
            loginUserId: `${details?.userId}`,
            loginUserName: details?.userName,
            AuditTrail: 0,
            editTrail: 0,
            dataSource: "",
            processStatus: "",
            deliveryStatus: "",
          },
          patientDto: {
            referenceId: UpdateLocationValue,
            PatientInfo: {
              cosmosKeyId: "",
              locationNumber: UpdateLocationValue,
              referenceId: UpdateLocationValue,
              status: "",
              dataSource: "",
              auditTrailLevel: 0,
              editTrailLevel: 0,
              processStatus: "Pending",
              deliveryStatus: "Delivered",
              firstName: values.firstName,
              lastName: values.lastName,
              dob: values.dateOfBirth,
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              cityTown: values.city,
              StateProvinceCode: values.state,
              postalCode: values.postalCode,
              // dob: values.dateOfBirth == null ? "" : values.dateOfBirth,
              email: values.email,
              ssn: values.ssn,
              phoneNumber: values?.mobileNumber
                ?.replace("(", "")
                .replace(")", "")
                .replace("-", "")
                .replace(" ", ""),
              gender: values.gender,
            },
          },
        });
      } catch (e) {
        logTelemetry("Failed", "DiscoverInsurance", "onSubmit", e?.message, e);
      }
    },
  });

  const handleMobileInputChange = (e) => {
    const newValue = e.target.value;

    if (newValue.length <= maxLength) {
      setFieldValue("mobileNumber", newValue);
      // setFieldValue("mobileNumber", formatPhoneNumber(newValue));
    }

    // const newValue = e.target.value;
    // if (newValue) {
    //   const previousValue = values?.mobileNumber;
    //   if (newValue?.length === previousValue?.length - 1) {
    //     setFieldValue("mobileNumber", formatPhoneNumber(newValue));
    //   } else if (newValue?.length <= maxLength) {
    //     setFieldValue("mobileNumber", formatPhoneNumber(newValue));
    //   }
    // }
  };

  useEffect(() => {
    if (data) {
      if (data?.status && data?.status?.code != 200) {
        logTelemetry(
          "Failed",
          "DiscoverInsurance",
          "333-useEffect",
          data?.status?.message
        );
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
      }
    }
  }, [data]);

  const handleBackButtonClick = () => {
    try {
      if (previousComponent) {
        // dispatch(setPreviousComponent(activeComponent));
        dispatch(setActiveComponent(previousComponent));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Grid container className="mb-2 mt-3 flex items-center">
          <Grid item xs={7} sm={8}>
            <Breadcrumb items={breadcrumbItems} isRedux={true} />
          </Grid>
          <Grid item xs={1} sm={1}>
            <div className="my-4 md:my-0 pr-2">
              <Button
                text="Clear"
                classname={"button-styles btnHeight"}
                filled={true}
                onclick={resetForm}
                disable={loading}
              />
            </div>
          </Grid>
          <Grid item xs={3} sm={2} className="mt-3 mb-2">
            <div className="my-4 md:my-0">
              <Button
                text="Discover Insurance"
                classname={"button-styles btnHeight"}
                filled={true}
                type="button"
                onclick={handleSubmit}
                disable={loading}
              />
            </div>
          </Grid>
          <Grid item xs={1} sm={1} className="mt-3 mb-2">
            <div className="my-4 md:my-0 pr-2 ml-1">
              <Button
                text="Back"
                classname={"button-styles btnHeight"}
                filled={true}
                onclick={handleBackButtonClick}
                // onclick={() => navigate(-1)}
                disable={loading}
              />
            </div>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            <Grid container className="flex justify-flex-start">
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={locationData}
                  size="small"
                  value={UpdateLocationName}
                  onChange={updateLocationItem}
                  // sx={{ width: 160 }}
                  style={textFieldStyles}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="flex justify-center"
                display={{ xs: "none", sm: "block" }}
              ></Grid>
              {(firstNameError ||
                lastNameError ||
                dateOfBirthError ||
                genderError ||
                addressLine1Error ||
                cityError ||
                stateError ||
                postalCodeError) && (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">Required Fields Missing !</Alert>
                </Grid>
              )}
              <Grid
                item
                md={12}
                sm={12}
                className="my-4"
                display={{ xs: "none", sm: "block" }}
              >
                <Divider variant="middle" />
              </Grid>
              <Grid container className="mx-4 mt-3 mb-2">
                <Grid item md={12} sm={12}>
                  <p className="text-slate-600 font-medium text-sm">
                    Demographics info
                  </p>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="flex"
                sx={{ justifyContent: "flex-start" }}
              >
                <TextField
                  id="outlined-first-input"
                  label="First Name"
                  error={
                    firstNameError == true
                      ? values.firstName == ""
                        ? true
                        : false
                      : false
                  }
                  name="First Name"
                  className="m-0"
                  size="small"
                  fullWidth
                  value={values.firstName}
                  onChange={handleChange("firstName")}
                  style={textFieldStyles}
                />
              </Grid>

              <Grid item xs={12} sm={6} className="flex justify-center">
                <TextField
                  id="outlined-last-input"
                  error={
                    lastNameError == true
                      ? values.lastName == ""
                        ? true
                        : false
                      : false
                  }
                  label="Last Name"
                  name="Last Name"
                  className="m-0"
                  value={values.lastName}
                  size="small"
                  onChange={handleChange("lastName")}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-center">
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="m-0"
                sx={{ margin: "12px" }}
                value={values.dateOfBirth}
                slotProps={{ textField: { fullWidth: true } }}
                placeholder="Date of Birth"
                label="Date of Birth"
                onChange={(e) => {
                  setFieldValue("dateOfBirth", e);
                }}
                inputFormat="dd-MM-yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider> */}
                <TextField
                  id="outlined-dob-input"
                  label="Date of Birth (MM/DD/YYYY)"
                  size="small"
                  name="Date of Birth"
                  className="m-0"
                  value={values.dateOfBirth}
                  onChange={handleChange("dateOfBirth")}
                  placeholder="MM/DD/YYYY"
                  error={
                    dateOfBirthError == true
                      ? values.dateOfBirth == ""
                        ? true
                        : false
                      : false
                  }
                  // error={errors.Location && touched.Location}
                  // className="m-0"
                  // onKeyDown={handlePrice}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-center">
                <FormControl style={textFieldStyles}>
                  <InputLabel id="gender-select-label" size="small">
                    Gender
                  </InputLabel>
                  <Select
                    error={genderError && values.gender === ""}
                    labelId="gender-select-label"
                    id="gender-select"
                    name="gender"
                    size="small"
                    value={values.gender}
                    label="Gender"
                    onChange={handleChange("gender")}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-center">
                <TextField
                  error={
                    addressLine1Error == true
                      ? values.addressLine1 == ""
                        ? true
                        : false
                      : false
                  }
                  id="outlined-address-input"
                  label="Address Line 1"
                  name="Address Line 1"
                  value={values.addressLine1}
                  size="small"
                  onChange={handleChange("addressLine1")}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="flex justify-center items-center mx-4"
              >
                <TextField
                  id="outlined-add2-input"
                  label="Address Line 2"
                  name="Address Line 2"
                  value={values.addressLine2}
                  size="small"
                  onChange={handleChange("addressLine2")}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="flex justify-center items-center mx-4"
              >
                <TextField
                  id="outlined-city-input"
                  label="City or Town"
                  name="City or Town"
                  value={values.city}
                  error={
                    cityError == true
                      ? values.city == ""
                        ? true
                        : false
                      : false
                  }
                  size="small"
                  onChange={handleChange("city")}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="flex justify-center items-center mx-4"
              >
                <TextField
                  id="outlined-state-input"
                  label="State or Province"
                  name="State or Province"
                  className="m-0"
                  value={values.state}
                  size="small"
                  error={
                    stateError == true
                      ? values.state == ""
                        ? true
                        : false
                      : false
                  }
                  onChange={handleChange("state")}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="flex justify-center items-center mx-4"
              >
                <TextField
                  id="outlined-passpostalword-input"
                  label="Postal Code"
                  className="m-0"
                  name="Postal Code"
                  value={values.postalCode}
                  error={
                    postalCodeError == true
                      ? values.postalCode == ""
                        ? true
                        : false
                      : false
                  }
                  size="small"
                  onChange={handleChange("postalCode")}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="flex justify-center items-center mx-4"
              >
                <TextField
                  id="outlined-mobile-input"
                  label="Mobile Number"
                  name="Mobile Number"
                  className="m-0"
                  value={values.mobileNumber}
                  onChange={handleMobileInputChange}
                  style={textFieldStyles}
                  size="small"
                  onKeyDown={handleNumberInput}
                  // InputLabelProps={{ shrink: values.mobileNumber == null ? true : false }}
                />
              </Grid>

              <Grid item xs={12} sm={6} className="flex flex-start">
                <TextField
                  id="outlined-email-input"
                  label="E-Mail Address"
                  name="E-Mail Address"
                  size="small"
                  className="m-0"
                  value={values.email}
                  onChange={handleChange("email")}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex flex-start">
                <TextField
                  id="outlined-ssn-input"
                  label="Social Security No"
                  name="SSN"
                  size="small"
                  className="m-0"
                  value={values.ssn}
                  onChange={handleChange("ssn")}
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                className="my-4"
                display={{ xs: "none", sm: "block" }}
              >
                <Divider variant="middle" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {data?.data?.coverageBenefits?.length > 0 && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <div className="w-full my-4">
                <div>
                  {/* <h 1  m-0 sm:mx-4 p-2  className="text-2xl font-medium my-2 text-slate-600">
            Benefit Details
          </h1> */}
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Benefit Summary
                  </Typography>
                  <Accordion
                    expanded={expandedsum}
                    onChange={handleChangesum(
                      `${data?.data?.patientInsurance?.payerName}`
                    )}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#f1f1f1" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Primary Insurance Payer (
                        {data?.data?.patientInsurance?.payerName})
                      </Typography>
                    </AccordionSummary>
                    <Grid container className="my-2" sx={{ pl: 2 }}>
                      <Grid xs={6}>
                        <Grid container className="my-2">
                          <Grid xs={5}>Payer Id</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.patientInsurance?.payerId}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Insurance Type</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {
                                      data?.data?.patientInsurance
                                        ?.insuranceType
                                    }
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container className="my-2">
                          <Grid xs={5}>Subscriber/Member ID</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.patientInsurance?.subscriberId}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Group No</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.patientInsurance?.groupNumber}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* <Grid container className="my-2">
                <Grid xs={5}>Provider Network Status</Grid>
                <Grid xs={5}>
                  <Grid container>
                    <Stack direction="row" spacing={1}>
                      <div>:</div>
                      <div><Typography style={{ overflowWrap: "break-word" }} variant="body1">
                      {BindInsuranceDetails(4)}
                        </Typography></div>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid> */}
                        <Grid container className="my-2">
                          <Grid xs={5}>Deductible On </Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {BindInsuranceDetails(1)}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={6}>
                        <Grid container className="my-2">
                          <Grid xs={5}>Plan Type</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.patientInsurance?.planType}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Coverage Status</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.eligibilityStatus}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container className="my-2">
                          <Grid xs={5}>Eligibility Dates</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  ></Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Primary Care Physician (PCP)</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {BindInsuranceDetails(3, "PCP")}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Coordination of benefits (COB)</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.insurances?.map((i) => {
                                      return <Grid>{i.payerName}</Grid>;
                                    })}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        xs={6}
                        sx={{
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                      >
                        <Card>
                          <CardContent sx={{ background: "#f4f4f4" }}>
                            <Typography
                              variant="overline"
                              sx={{ fontWeight: 600 }}
                              gutterBottom
                            >
                              In-Network
                            </Typography>
                            <Divider />
                            <Grid container className="my-2">
                              <Grid xs={5}>Co-Insurance</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 1)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Physician Office Visit Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 2)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Specialist Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 3)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Telemedicine Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 4)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Behavioral Health Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 5)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Individual Deductibles </Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 6)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Family Deductibles</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 7)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Max Out-of-Pocket Individual</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 8)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Max Out-of-Pocket Family</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(3, 9)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid
                        xs={6}
                        sx={{ paddingRight: "10px", paddingBottom: "5px" }}
                      >
                        <Card>
                          <CardContent sx={{ background: "#f4f4f4" }}>
                            <Typography
                              sx={{ fontWeight: 600 }}
                              variant="overline"
                              gutterBottom
                            >
                              Out-of-Network
                            </Typography>{" "}
                            <Divider />
                            <Grid container className="my-2">
                              <Grid xs={5}>Co-Insurance</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(4, 1)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Physician Office Visit Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(4, 2)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Specialist Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(4, 3)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Telemedicine Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(4, 4)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Behavioral Health Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(4, 5)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Individual Deductibles </Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(4, 6)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Family Deductibles</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(4, 7)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Max Out-of-Pocket Individual</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceDetails(4, 8)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Max Out-of-Pocket Family</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {" "}
                                        {BindInsuranceDetails(4, 9)}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Accordion>

                  {data?.data?.insurances?.map((i) => {
                    return (
                      <Accordion
                        expanded={expandedsumMul === `${i.payerName}`}
                        onChange={MultihandleChange(`${i.payerName}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          style={{ background: "#f1f1f1" }}
                        >
                          <Typography
                            component="div"
                            gutterBottom
                            sx={{
                              width: "73%",
                              flexShrink: 0,
                              fontWeight: "bold",
                            }}
                            variant="body2"
                          >
                            {i.priority} ({i.payerName})
                          </Typography>
                          {/* <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {i.coverageCode}
                  </Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container className="my-2" sx={{ pl: 2 }}>
                            <Grid xs={6}>
                              <Grid container className="my-2">
                                <Grid xs={5}>Payer Id</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.payerId}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Insurance Type</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.insuranceType}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid container className="my-2">
                                <Grid xs={5}>Subscriber/Member ID</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.subscriberId}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Group No</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.groupNumber}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* <Grid container className="my-2">
                <Grid xs={5}>Provider Network Status</Grid>
                <Grid xs={5}>
                  <Grid container>
                    <Stack direction="row" spacing={1}>
                      <div>:</div>
                      <div><Typography style={{ overflowWrap: "break-word" }} variant="body1">
                      {BindInsuranceDetails(4)}
                        </Typography></div>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid> */}
                              <Grid container className="my-2">
                                <Grid xs={5}>Deductible On </Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        ></Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={6}>
                              <Grid container className="my-2">
                                <Grid xs={5}>Plan Type</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.planType}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Coverage Status</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.eligibilityStatus}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid container className="my-2">
                                <Grid xs={5}>Eligibility Dates</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        ></Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Primary Care Physician (PCP)</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        ></Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>
                                  Coordination of benefits (COB)
                                </Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        ></Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {data?.data?.coverageBenefits?.length > 0 && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <div className="w-full my-4">
                <div>
                  {/* <h 1  m-0 sm:mx-4 p-2  className="text-2xl font-medium my-2 text-slate-600">
            Benefit Details
          </h1> */}
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Benefit Details
                  </Typography>
                  {/* <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  mx={1}
                  sx={{ fontWeight: "bold" }}
                >
                  Benefit Details
                </Typography> */}
                  <Divider sx={{ mb: 2 }} />
                  {/* {data?.data?.eligibilityStatus != "" ? (
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{ fontWeight: "medium" }}
              mx={1}
              my={2}
            >
              Eligibility Status : {data?.data?.eligibilityStatus}
            </Typography>
          ) : null} */}
                  {/* {data?.data?.eligibilityNotes != "" &&
          data?.data?.eligibilityNotes != null ? (
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{ fontWeight: "medium" }}
              mx={1}
              my={2}
            >
              Notes : {data?.data?.eligibilityNotes}
            </Typography>
          ) : null} */}

                  {data?.data?.coverageBenefits?.map((i) => {
                    return (
                      <Accordion
                        expanded={expanded === `${i.coverage}`}
                        onChange={handleChangeDetails(`${i.coverage}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            component="div"
                            gutterBottom
                            sx={{
                              width: "73%",
                              flexShrink: 0,
                              fontWeight: "bold",
                            }}
                            variant="body2"
                          >
                            {i.coverage} : {i.coverageCode}
                          </Typography>
                          {/* <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {i.coverageCode}
                  </Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer>
                            {/* <TableContainer component={Paper}> */}
                            <Table
                              sx={{ minWidth: 650 }}
                              size="small"
                              aria-label="a dense table"
                            >
                              <TableHead style={{ background: "#f1f1f1" }}>
                                <TableRow>
                                  <TableCell>Eligibility Benefit</TableCell>
                                  <TableCell>Coverage</TableCell>
                                  <TableCell>Insurance Type</TableCell>
                                  <TableCell>Coverage Plan</TableCell>
                                  <TableCell>Coverage Amount</TableCell>
                                  <TableCell>Coverage %</TableCell>
                                  <TableCell>Qty.Qualifie</TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell>Auth Required</TableCell>
                                  <TableCell>Additional Info</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {i?.benefits.map((row) => (
                                  <TableRow
                                    key={row.title}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.title}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.coverageCode}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.insuranceType}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.coveragePlan}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.coverageAmt}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.coveragePercentage}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.qtyQualifier}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.quantity}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.authRequired}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.additionalInfo}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </Container>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default DiscoverInsurance;
