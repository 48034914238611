import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import Tooltip from "@mui/material/Tooltip";
import getColor from "../../components/utils/getColor";
import Typography from "@mui/material/Typography";

const ellipStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "pointer",
};

const alignInSingle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "fit-content",
  display: "flex",
  alignItems: "baseline",
  gap: "5px",
};

const CustomPieChart = React.memo(
  ({
    data: pieData,
    onItemClick,
    index,
    pIndex,
    cData,
    pData,
    by,
    isFrom,
    e,
    latency,
    pList,
  }) => {
    function clickHandler(item, index, pIndex, cData, pData) {
      onItemClick(item, index, pIndex, cData, pData, by, e, pList);
    }
    let data = pieData?.slice(0, 10);
    if (isFrom == "single") {
      let total = pieData?.reduce(
        (a, c) => parseInt(c?.totalSubRequests) + a,
        0
      );
      const newData = pieData?.map((category, index) => {
        let totalvalue = (parseFloat(category?.totalSubRequests) * 100) / total;
        return {
          ...category,
          value: category?.totalSubRequests,
          color: getColor(category.subRequestType),
          label: `${category.subRequestType}`,
        };
      });
      data = newData;
    }
    return (
      <div className="!flex">
        <div className="!flex-1">
          <PieChart
            series={[
              {
                data: data,
                outerRadius: 90,
                cx: isFrom == "single" ? 100 : 100,
                cy: isFrom == "single" ? 120 : 100,
              },
            ]}
            width={230}
            height={250}
            margin={{ top: 10, bottom: 0, left: 10, right: 10 }}
            slotProps={{ legend: { hidden: true } }}
            onItemClick={(e, d, item) => {
              if (isFrom == "single") {
                onItemClick(d, item, pieData, pIndex, e, pList);
              } else {
                clickHandler(item, index, pIndex, cData, pData);
              }
            }}
            // skipAnimation
          />
        </div>
        <div
          className="flex-grow"
          style={{
            maxHeight: "300px",
            overflowY: "auto",
            marginTop: isFrom == "single" ? "4rem" : "0",
          }}
        >
          {pieData && pieData.length > 0 && isFrom != "single"
            ? pieData.slice(0, 10).map((item, index) => (
                <React.Fragment
                  key={item.displayName + item.payerId + item.type + index}
                >
                  <span style={alignInSingle}>
                    <>
                      <span
                        style={{
                          minWidth: "10px",
                          height: "10px",
                          display: "inline-block",
                          backgroundColor: item.color,
                          marginRight: "2px",
                        }}
                      />
                      <Tooltip title={item.displayName}>
                        <Typography
                          variant="subtitle1"
                          style={ellipStyle}
                          onClick={(e, data) => {
                            clickHandler(item, index, pIndex, cData, pData);
                          }}
                        >
                          {item?.displayName}
                        </Typography>
                      </Tooltip>
                    </>

                    <span id={item.displayName + item.payerId + item.type + index}>
                      <span>
                        {item?.payerId?.length > 0
                          ? "(" + item?.payerId + ")"
                          : ""}
                      </span>
                      ({item.value}
                      {
                      latency?.includes("Lat") && item.value !== 0
                        ? e?.[pIndex]?.requestCode == "CS"
                          ? " Hr"
                          : " Min"
                        : ""}
                      )
                    </span>
                  </span>
                </React.Fragment>
              ))
            : data?.map((item, ind) => (
                <React.Fragment key={item.label + item.payerId + ind}>
                  <span style={alignInSingle}>
                    <>
                      <span
                        style={{
                          minWidth: "10px",
                          height: "10px",
                          display: "inline-block",
                          backgroundColor: item.color,
                          marginRight: "2px",
                        }}
                      />
                      <Tooltip title={item.label}>
                        <Typography
                          variant="subtitle1"
                          style={ellipStyle}
                          onClick={(e, data) => {
                            onItemClick(
                              { dataIndex: ind },
                              item,
                              pieData,
                              pIndex,
                              e,
                              pList
                            );
                          }}
                        >
                          {item.label}
                          {/* {item.label.length > 20
                            ? item.label.substring(0, 20) + "..."
                            : item.label} */}
                        </Typography>
                      </Tooltip>
                    </>

                    <span>({item.value})</span>
                  </span>
                </React.Fragment>
              ))}
        </div>
      </div>
    );
  }
);

export default CustomPieChart;
